import React, { createContext, useState, useEffect } from "react";

export const RoleContext = createContext();

export const RoleProvider = ({ children }) => {
  // Lazy initialize values from localStorage
  const [userRole, setUserRole] = useState(() => localStorage.getItem("userRole") || "User");
  const [effectiveRole, setEffectiveRole] = useState(() => localStorage.getItem("effectiveRole") || "Admin");
  const [isRoleSwitched, setIsRoleSwitched] = useState(() => localStorage.getItem("isRoleSwitched") === "true");

  // Persist changes to localStorage
  useEffect(() => {
    localStorage.setItem("userRole", userRole);
  }, [userRole]);

  useEffect(() => {
    localStorage.setItem("effectiveRole", effectiveRole);
  }, [effectiveRole]);

  useEffect(() => {
    localStorage.setItem("isRoleSwitched", isRoleSwitched.toString());
  }, [isRoleSwitched]);

  return (
    <RoleContext.Provider
      value={{
        effectiveRole,
        setEffectiveRole,
        isRoleSwitched,
        setIsRoleSwitched,
        userRole,
        setUserRole,
      }}
    >
      {children}
    </RoleContext.Provider>
  );
};
