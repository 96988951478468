import { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { Dialog, Transition } from '@headlessui/react';
import ConfirmationModal from './ConfirmationModal'; // Import the confirmation modal

const Users = () => {
  const [users, setUsers] = useState([]);
  const [activeTab, setActiveTab] = useState('active'); // 'active' or 'inactive'
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('Client Manager');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [editingUser, setEditingUser] = useState(null);
  const [editedPassword, setEditedPassword] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editedRole, setEditedRole] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); // State for delete confirmation modal
  const [userToDelete, setUserToDelete] = useState(null); // State to track the user to delete
  const [isSaving, setIsSaving] = useState(false);

  const fetchUsers = async () => {
    try {
      const response = await axios.get('/api/users');
      setUsers(response.data);
      setLoading(false);
    } catch (error) {
      setError('Failed to fetch users');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleCreateUser = async (e) => {
    e.preventDefault(); 
    setIsSaving(true);
    try {
      await axios.post('/api/users', {
        email,
        password,
        role,
        first_name: firstName,
        last_name: lastName
      });
  
      if (selectedFile) {
        const formData = new FormData();
        formData.append('profileImage', selectedFile);
        await axios.post(`/api/users/${email}/upload`, formData);
      }
      
      resetForm();
      fetchUsers(); 
    } catch (error) {
      setError('Failed to create user');
    } finally {
      setIsSaving(false);
    }
  };

  const handleUpdateUser = async (e) => {
    e.preventDefault();
    setIsSaving(true);
  
    try {
      const updatedUserData = {
        email, 
        newEmail: email,
        role: editedRole,
        first_name: firstName,
        last_name: lastName,
      };
      
      if (editedPassword) {
        updatedUserData.password = editedPassword;
      }
      
  
      await axios.put(`/api/users/${editingUser.email}`, updatedUserData);
  
      if (selectedFile) {
        const formData = new FormData();
        formData.append('profileImage', selectedFile);
        await axios.post(`/api/users/${email}/upload`, formData);
      }
  
      resetForm();
      await fetchUsers(); 
    } catch (error) {
      setError('Failed to update user');
    } finally {
      setIsSaving(false);
    }
  };

  const handleDeleteUser = async () => {
    try {
      await axios.delete(`/api/users/${userToDelete.email}`);
      setIsDeleteModalOpen(false);
      setUserToDelete(null);
      await fetchUsers();
    } catch (error) {
      console.error('Failed to delete user:', error);
      setError('Failed to delete user');
    }
  };

  const handleDeactivateUser = async (user) => {
    try {
      const isActive = !user.is_active; // Toggle active status
      await axios.put(`/api/users/${user.email}/deactivate`, { isActive });
      await fetchUsers();
    } catch (error) {
      console.error('Failed to update user status:', error.message);
      setError('Failed to update user status.');
    }
  };

  const openDeleteModal = (user) => {
    setUserToDelete(user);
    setIsDeleteModalOpen(true);
  };

  const resetForm = () => {
    setEmail('');
    setPassword('');
    setRole('Client Manager');
    setFirstName('');
    setLastName('');
    setSelectedFile(null);
    setEditingUser(null);
    setModalIsOpen(false);
  };

  const openEditModal = (user) => {
    setEditingUser(user);
    setEmail(user.email);
    setFirstName(user.first_name);
    setLastName(user.last_name);
    setEditedRole(user.role);
    setRole(user.role);
    setModalIsOpen(true);
  };

  const openCreateModal = () => {
    resetForm();
    setModalIsOpen(true);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  // Filter users based on the active tab.
  const filteredUsers = users.filter(user =>
    activeTab === 'active' ? user.is_active : !user.is_active
  );

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center justify-between">
        <div className="sm:flex-auto">
        <h1 className="mt-3 text-3xl font-bold tracking-tight text-slate-900">Users</h1>
        </div>
        <button
          onClick={openCreateModal}
          className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"
        >
          Add User
        </button>
      </div>

      {/* Tabs for Active and Inactive */}
      <div className="flex space-x-4 my-4">
        <button
          onClick={() => setActiveTab('active')}
          className={`px-4 py-2 rounded ${activeTab === 'active' ? 'bg-indigo-600 text-white' : 'bg-gray-200 text-gray-700'}`}
        >
          Active
        </button>
        <button
          onClick={() => setActiveTab('inactive')}
          className={`px-4 py-2 rounded ${activeTab === 'inactive' ? 'bg-indigo-600 text-white' : 'bg-gray-200 text-gray-700'}`}
        >
          Inactive
        </button>
      </div>

      {/* Users Table */}
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">Profile Image</th>
                  <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">First Name</th>
                  <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">Last Name</th>
                  <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">Email</th>
                  <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">Role</th>
                  <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">Actions</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {filteredUsers.map((user) => (
                  <tr key={user.email}>
                    <td className="py-4 pl-4 pr-3 text-sm">
                      {user.profile_image ? (
                        <img src={user.profile_image} alt="Profile" className="h-10 w-10 rounded-full" />
                      ) : (
                        'No Image'
                      )}
                    </td>
                    <td className="py-4 pl-4 pr-3 text-sm">{user.first_name || 'N/A'}</td>
                    <td className="py-4 pl-4 pr-3 text-sm">{user.last_name || 'N/A'}</td>
                    <td className="py-4 pl-4 pr-3 text-sm">{user.email}</td>
                    <td className="py-4 pl-4 pr-3 text-sm">{user.role || 'No Role Assigned'}</td>
                    <td className="py-4 pl-4 pr-3 text-sm">
                      <button
                        onClick={() => openEditModal(user)}
                        className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => openDeleteModal(user)}
                        className="inline-flex items-center ml-2 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500"
                      >
                        Delete
                      </button>
                      <button
                        onClick={() => handleDeactivateUser(user)}
                        className={`inline-flex items-center ml-2 rounded-md ${user.is_active ? 'bg-gray-600' : 'bg-green-600'} px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500`}
                      >
                        {user.is_active ? 'Deactivate' : 'Activate'}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Dialog for Create/Edit User */}
      <Transition appear show={modalIsOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setModalIsOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    {editingUser ? 'Edit User' : 'Create User'}
                  </Dialog.Title>
                  <form className="mt-2 space-y-4" onSubmit={editingUser ? handleUpdateUser : handleCreateUser}>
                    {/* Form Fields */}
                    <div>
                      <label className="block text-sm font-medium text-gray-700">First Name</label>
                      <input
                        type="text"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                        className="mt-1 px-3 py-2 border rounded-md w-full"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Last Name</label>
                      <input
                        type="text"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                        className="mt-1 px-3 py-2 border rounded-md w-full"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Email</label>
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className="mt-1 px-3 py-2 border rounded-md w-full"
                      />
                    </div>
                    <div>
  <label className="block text-sm font-medium text-gray-700">Password</label>
  <input
    type="password"
    // When editing, use editedPassword; otherwise, use password.
    value={editingUser ? editedPassword : password}
    onChange={(e) =>
      editingUser ? setEditedPassword(e.target.value) : setPassword(e.target.value)
    }
    placeholder={editingUser ? "Leave blank to keep current password" : ""}
    className="mt-1 px-3 py-2 border rounded-md w-full"
  />
</div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700">Role</label>
                      <select
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                        required
                        className="mt-1 px-3 py-2 border rounded-md w-full"
                      >
                        <option value="Admin">Admin</option>
                        <option value="Assistant">Assistant</option>
                        <option value="Client Manager">Client Manager</option>
                      </select>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Profile Image</label>
                      <input
                        type="file"
                        onChange={(e) => setSelectedFile(e.target.files[0])}
                        className="mt-1 px-3 py-2 border rounded-md w-full"
                      />
                    </div>
                    {/* Action Buttons */}
                    <div className="mt-4 flex justify-end gap-4">
                      <button
                        type="button"
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200"
                        onClick={() => setModalIsOpen(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        disabled={isSaving}
                        className={`inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 ${isSaving ? 'opacity-50 cursor-not-allowed' : ''}`}
                      >
                        {isSaving ? 'Saving...' : editingUser ? 'Update User' : 'Create User'}
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* Confirmation Modal for Deleting User */}
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDeleteUser}
        title="Delete User"
        message={`Are you sure you want to delete ${userToDelete?.email}? This action cannot be undone.`}
      />
    </div>
  );
};

export default Users;
