import React, { useEffect, useState, useContext } from 'react';
import { Dialog, DialogBackdrop, DialogPanel, Menu, MenuButton, MenuItem, MenuItems, TransitionChild } from '@headlessui/react';
import { BellIcon, ChevronDownIcon, CheckCircleIcon, UserCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Bars3Icon, MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Switch } from "@headlessui/react";
import { RoleContext } from "./RoleContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Layout({ sidebarOpen, setSidebarOpen, navigation, handleNavigationClick, children }) {
  // ✅ Use useContext only once
  const { effectiveRole, setEffectiveRole, isRoleSwitched, setIsRoleSwitched } = useContext(RoleContext);
  

    // ✅ Ensure values are set from localStorage on mount
    useEffect(() => {
      const storedRole = localStorage.getItem("effectiveRole");
      const storedSwitchState = localStorage.getItem("isRoleSwitched") === "true";
  
      if (storedRole) setEffectiveRole(storedRole);
      if (setIsRoleSwitched) setIsRoleSwitched(storedSwitchState);
    }, [setEffectiveRole, setIsRoleSwitched]);

  // ✅ Role Toggle - Stores state in localStorage
  const toggleRole = () => {
    const newSwitchState = !isRoleSwitched;
    // When toggled, set the effective role based on the switch state.
    const newRole = newSwitchState ? "Client Manager" : "Admin";
    setIsRoleSwitched(newSwitchState);
    setEffectiveRole(newRole);
  
    // Persist in localStorage if desired
    localStorage.setItem("isRoleSwitched", newSwitchState);
    localStorage.setItem("effectiveRole", newRole);
  };
  
  // ✅ Always use useContext() without conditions
  const roleContext = useContext(RoleContext);

  
  const [userEmail, setUserEmail] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [profileImage, setProfileImage] = useState(null);
  const [userRole, setUserRole] = useState('');
  const [newTasks, setNewTasks] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);



  const filteredNavigation = navigation.filter((item) => {
    return !(item.name === "Sales Book" && effectiveRole !== "Admin");
  });
  
  // ✅ Fetch Current User Data
  const fetchCurrentUser = async () => {
    const token = localStorage.getItem('token');
    if (!token) return;
  
    try {
      const response = await axios.get('/api/current-user', {
        headers: { Authorization: `Bearer ${token}` },
      });
      const { email, first_name, last_name, profile_image, role } = response.data;
  
      setUserEmail(email);
      setFirstName(first_name);
      setLastName(last_name);
      setProfileImage(profile_image);
      setUserRole(role);
  
      // // Always update the effectiveRole to match the API's role
      // setEffectiveRole(role);
      // // Optionally, update the localStorage as well:
      // localStorage.setItem("effectiveRole", role);
    } catch (error) {
      console.error("Error fetching current user:", error);
    }
  };
  

  useEffect(() => {
    fetchCurrentUser();
  }, []);

    const fetchNewTasks = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error("Token not found");
        return;
      }
    
      try {
        const response = await axios.get('/api/notes', {
          headers: { Authorization: `Bearer ${token}` },
        });
    
        // Log the API response to ensure we're getting the correct data
        console.log("Notes API response:", response.data);
    
        // Ensure userEmail is available
        console.log("User email:", userEmail);
    
        // Filter tasks where 'type' is either null or 'Task', and 'status' is not 'Done'
        const tasks = response.data.filter(
          note => 
            (note.type === 'Task' || note.type === null) &&  // Adjusting for the 'null' case in 'type'
            note.assigned_user &&  // Ensure the task is assigned to someone
            note.assigned_user.toLowerCase() === userEmail.toLowerCase() &&  // Match the current user
            note.status !== 'Done'  // Task should not be marked as 'Done'
        );
    
        // Log the filtered tasks
        console.log("Filtered tasks for the current user:", tasks);
    
        setNewTasks(tasks); // Store new tasks
      } catch (error) {
        console.error("Error fetching tasks:", error.response ? error.response.data : error.message);
      }
    };
    
    
  
    useEffect(() => {
      fetchCurrentUser();
    }, []);
  
    useEffect(() => {
      if (userEmail) {
        fetchNewTasks(); // Fetch tasks once the user email is available
      }
    }, [userEmail]);
  
    const handleMarkAsDone = async (noteId, clientId) => {
      try {
        await axios.put(`/api/clients/${clientId}/notes/${noteId}/status`, { status: 'Done' });
        setNewTasks(prevTasks => prevTasks.filter(task => task.id !== noteId)); // Remove task from list
      } catch (error) {
        console.error('Error marking task as done:', error);
      }
    };

      // New function to generate tasks
  const handleGenerateTasks = async () => {
    try {
      await axios.post('/api/create-task-notes'); // Call your API to generate tasks
      alert("Tasks have been successfully generated!");
    } catch (error) {
      console.error('Error generating tasks:', error);
      alert("Failed to generate tasks.");
    }
  };

  const userNavigation = [
    { 
      name: 'My Profile', 
      href: '/profile'
    },
    { 
      name: 'Generate Tasks', 
      href: '#', 
      onClick: handleGenerateTasks // Call the function on click 
    },
    { name: 'Sign out', href: '/logout' },  // <-- Add the Logout link
  ];

  return (
    <div>
      <Dialog open={sidebarOpen} onClose={setSidebarOpen} className="relative z-50 lg:hidden">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
        />

        <div className="fixed inset-0 flex">
          <DialogPanel
            transition
            className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full"
          >
            <TransitionChild>
              <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                <button type="button" onClick={() => setSidebarOpen(false)} className="-m-2.5 p-2.5">
                  <span className="sr-only">Close sidebar</span>
                  <XMarkIcon aria-hidden="true" className="h-6 w-6 text-white" />
                </button>
              </div>
            </TransitionChild>
            <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
              <div className="flex h-16 shrink-0 items-center">
                <img alt="TutorChase" src="/logo192.png" className="h-12 w-auto" />
              </div>
              <nav className="flex flex-1 flex-col">
                <ul role="list" className="flex flex-1 flex-col gap-y-7">
                  <li>
                    <ul role="list" className="-mx-2 space-y-1">
                      {filteredNavigation.map((item) => (
                        <li key={item.name}>
                          <Link
                            to={item.href}
                            onClick={() => handleNavigationClick(item.name)}
                            className={classNames(
                              item.current
                                ? 'bg-gray-50 text-indigo-600'
                                : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
                              'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                            )}
                          >
                            <item.icon
                              aria-hidden="true"
                              className={classNames(
                                item.current ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
                                'h-6 w-6 shrink-0',
                              )}
                            />
                            {item.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                </ul>
              </nav>
            </div>
          </DialogPanel>
        </div>
      </Dialog>

      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
        <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
          <div className="flex h-16 shrink-0 items-center">
            <img alt="TutorChase" src="/logo192.png" className="h-12 w-auto" />
          </div>
          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" className="-mx-2 space-y-1">
                {navigation.map((item) => (
  <li key={item.name}>
    <Link
      to={item.href}
      onClick={() => handleNavigationClick(item.name)}
      className={classNames(
        item.current
          ? 'bg-gray-50 text-indigo-600'
          : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
        'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
      )}
    >
      <item.icon
        aria-hidden="true"
        className={classNames(
          item.current ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
          'h-6 w-6 shrink-0',
        )}
      />
      {item.name}
    </Link>
  </li>
))}
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className="lg:pl-72">
        <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
          <button type="button" onClick={() => setSidebarOpen(true)} className="-m-2.5 p-2.5 text-gray-700 lg:hidden">
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>

          <div aria-hidden="true" className="h-6 w-px bg-gray-900/10 lg:hidden" />

          <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
            <form action="#" method="GET" className="relative flex flex-1">
              <label htmlFor="search-field" className="sr-only">Search</label>
              <MagnifyingGlassIcon aria-hidden="true" className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400" />
              <input
                id="search-field"
                name="search"
                type="search"
                placeholder="Search..."
                className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
              />
            </form>
            <div className="flex items-center gap-x-4 lg:gap-x-6">

              {/* Notification Bell Icon with Badge */}
              <div className="relative flex items-center">
                <button
                  type="button"
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                  className="relative rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />

                  {/* Notification Badge */}
                  {newTasks.length > 0 && (
                    <span className="absolute top-0 right-0 block h-2 w-2 rounded-full bg-red-600 ring-2 ring-white" />
                  )}
                </button>

              
                {/* Dropdown for Task Notifications */}
{dropdownOpen && (
  <div className="absolute right-0 top-full mt-2 w-96 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
    <div className="p-4">
      <h3 className="text-sm font-medium text-gray-900">Pending Tasks</h3>
      <div className="mt-3 space-y-2">
        {newTasks.length > 0 ? (
          newTasks.map(task => (
            <div key={task.id} className="flex items-center justify-between p-2 bg-gray-50 rounded-md">
              <div>
                <p className="text-sm font-medium text-gray-700">{task.note_text}</p>
                <Link to={`/notes?noteId=${task.id}`} className="text-sm text-indigo-600 hover:underline">
  View Details
</Link>


              </div>
              <button
                onClick={() => handleMarkAsDone(task.id, task.client_id)}
                className="inline-flex items-center px-2 py-1 text-xs font-medium text-green-700 bg-green-50 rounded-md hover:bg-green-100"
              >
                <CheckCircleIcon className="h-4 w-4 mr-1" />
                Mark as Done
              </button>
            </div>
          ))
        ) : (
          <p className="text-sm text-gray-500">No new tasks</p>
        )}
      </div>
    </div>
  </div>
)}

              </div>

              <Menu as="div" className="relative flex items-center">
  <MenuButton className="-m-1.5 flex items-center p-1.5">
    <span className="sr-only">Open user menu</span>
    {profileImage ? (
      <img src={profileImage} alt="Profile" className="h-8 w-8 rounded-full" />
    ) : (
      <UserCircleIcon className="h-8 w-8 rounded-full text-gray-400" />
    )}
    <span className="hidden lg:flex lg:items-center">
      <span aria-hidden="true" className="ml-4 text-sm font-semibold leading-6 text-gray-900">
        {firstName} {lastName}
      </span>
      <span className="ml-2 text-sm leading-6 text-gray-500">
        ({effectiveRole}) {/* Show the switched role */}
      </span>
      <ChevronDownIcon aria-hidden="true" className="ml-2 h-5 w-5 text-gray-400" />
    </span>
  </MenuButton>

  {/* Role Toggle Switch */}
  {userRole === "Admin" && (
     <div className="ml-4 flex items-center">
     <Switch
       checked={isRoleSwitched}
       onChange={toggleRole}
       className={`${isRoleSwitched ? "bg-blue-600" : "bg-gray-200"} relative inline-flex h-6 w-11 items-center rounded-full`}
     >
       <span className="sr-only">Switch Role</span>
       <span
         className={`${isRoleSwitched ? "translate-x-6" : "translate-x-1"} inline-block h-4 w-4 transform rounded-full bg-white transition`}
       />
     </Switch>
     <span className="ml-2 text-sm text-gray-500">
       {isRoleSwitched ? "Client Manager View" : "Admin View"}
     </span>
   </div>
  )}

  <MenuItems className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5">
    {userNavigation.map((item) => (
      <MenuItem key={item.name}>
        <a
          href={item.href}
          className="block px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50"
          onClick={item.onClick ? item.onClick : null}
        >
          {item.name}
        </a>
      </MenuItem>
    ))}
  </MenuItems>
</Menu>



            </div>
          </div>
        </div>

        <main className="py-10">
          <div className="px-4 sm:px-6 lg:px-8">
            {children}
          </div>
        </main>
      </div>
    </div>
  );
}
