import React, { useEffect, useState, useCallback, useContext } from "react";
import { RoleContext } from "../RoleContext"; // Ensure correct import
import axios from "axios";
import { Link } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import Popover from "@mui/material/Popover";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { EllipsisHorizontalIcon } from "@heroicons/react/20/solid";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import isoweek from "dayjs/plugin/isoWeek";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import "dayjs/locale/en"; // Import your desired locale, e.g., English

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const statuses = {
  Paid: "text-green-700 bg-green-50 ring-green-600/20",
  Unpaid: "text-red-700 bg-red-50 ring-red-600/10",
};

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isoweek);
dayjs.extend(localeData);
dayjs.extend(weekday); // Place after localeData if it is locale-specific

// Set locale if necessary
dayjs.locale("en");

// const cardData = [
//   { title: "Connected", key: "connected", image: "/link.png" },
//   { title: "First Lesson Complete", key: "firstLessonComplete", image: "/first.png" },
//   { title: "Won", key: "wonJobs", image: "/won.png" },
//   { title: "Packages", key: "packageCount", image: "/package.png" }, // Updated key for package count
//   { title: "Lost - Pre Connection", key: "lostPreConnection", image: "/lostpre.png" },
//   { title: "Lost - Post Connection", key: "lostPostConnection", image: "/lostpost.png" },

// ];

const firstRowCards = [
    { title: "Won", key: "wonJobs", image: "/won.png" },
 
    { title: "Packages", key: "package_count", image: "/package.png" },
];

const secondRowCards = [
  // {
  //   title: "Lost - Pre Connection",
  //   key: "lostPreConnection",
  //   image: "/lostpre.png",
  //   subtitle: "Marked 'lost' manually in the pipeline before connecting with tutor",
  // },
  { title: "Connected", key: "connected", image: "/link.png" },
  {
    title: "First Lesson Complete",
    key: "firstLessonComplete",
    image: "/first.png",
  }
];

const thirdRowCards = [
 
  {
    title: "Lost - No Session",
    key: "lostPostConnection",
    image: "/lostpost.png",
    subtitle: "Marked 'finished' but no sessions",
  },
  {
    title: "Lost - First Session",
    key: "lostFirstSession",
    image: "/lostfirst.png",
    subtitle: "Marked 'finished' but had 1 session",
  },
  
];


const shortcutsItems = [
  {
    label: "Today", // Changed label from "Last 7 Days" to "Today"
    getValue: () => {
      const today = dayjs();
      return [today.startOf("day"), today.endOf("day")]; // Set start and end to today
    },
  },
  {
    label: "This Week",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("week"), today.endOf("week")];
    },
  },
  {
    label: "Last Week",
    getValue: () => {
      const today = dayjs();
      const prevWeek = today.subtract(7, "day");
      return [prevWeek.startOf("week"), prevWeek.endOf("week")];
    },
  },

  {
    label: "Last Month",
    getValue: () => {
      const today = dayjs();
      const lastMonth = today.subtract(1, "month");
      return [lastMonth.startOf("month"), lastMonth.endOf("month")];
    },
  },
  {
    label: "Current Month",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("month"), today.endOf("month")];
    },
  },
  {
    label: "This Year",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("year"), today.endOf("year")];
    },
  },
  {
    label: "Last Year",
    getValue: () => {
      const today = dayjs();
      const lastYear = today.subtract(1, "year");
      return [lastYear.startOf("year"), lastYear.endOf("year")];
    },
  },
  { label: "Reset", getValue: () => [null, null] },
];

const Leaderboard = () => {
  const { effectiveRole } = useContext(RoleContext); // ✅ Access role from context

  const [salesManagers, setsalesManagers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loggedInUser, setLoggedInUser] = useState(null); // Add state for the logged-in user
  const [modifiedCommissions, setModifiedCommissions] = useState([]);
  const [selectedManager, setSelectedManager] = useState(null); // State for selected manager
  const [monthlyCommission, setMonthlyCommission] = useState([]); // To store the commission data for each month
  const [expandedRows, setExpandedRows] = useState({}); // State to track expanded rows
  const [lostFirstSessionCount, setLostFirstSessionCount] = useState(0); // Correctly declared here
  const [runningCommission, setRunningCommission] = useState([]);
  const [finalizedCommission, setFinalizedCommission] = useState([]);

  // First declare `fetchRunningCommission` before using it
  const fetchRunningCommission = useCallback(async (managerEmail) => {
    if (!managerEmail) return;
    setLoading(true);
    try {
      const response = await axios.get(
        `/api/commission/running/${managerEmail}`
      );
      console.log("Running commission data:", response.data);
      setRunningCommission(response.data);
    } catch (error) {
      console.error("Error fetching running commission:", error);
      setRunningCommission([]);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchFinalizedCommission = useCallback(async () => {
    try {
      const response = await axios.get("/api/commission-payments", {
        params: { manager: selectedManager },
      });

      console.log("Fetched finalized commission data:", response.data);
      const updatedCommission = response.data.map((commission) => ({
        month_year: commission.month,
        total_commission: parseFloat(commission.amount) || 0,
        paidStatus: commission.status === "Paid" ? "Paid" : "Unpaid",
        payment_date: commission.payment_date, // Ensure this field is passed from backend
        appointments: commission.appointments || [], // Ensure appointments are passed correctly
      }));

      setFinalizedCommission(updatedCommission);
    } catch (error) {
      console.error("Error fetching finalized commission:", error);
    }
  }, [selectedManager]);

  const fetchCommissionPayments = useCallback(async () => {
    try {
      const response = await axios.get("/api/commission-payments", {
        params: { manager: selectedManager },
      });

      console.log("Fetched finalized commission data:", response.data);

      const updatedCommission = response.data.map((commission) => ({
        month_year: commission.month,
        total_commission: parseFloat(commission.amount) || 0,
        paidStatus: commission.status === "Paid" ? "Paid" : "Unpaid",
        payment_date: commission.payment_date, // Ensure this field is passed from backend
        appointments: commission.appointments || [], // Ensure appointments are passed correctly
      }));

      setFinalizedCommission(updatedCommission);
    } catch (error) {
      console.error("Error fetching finalized commission:", error);
    }
  }, [selectedManager]);

  useEffect(() => {
    if (selectedManager) {
      fetchYearlyCommission(selectedManager);
    }
  }, [selectedManager]);

  useEffect(() => {
    if (selectedManager) {
      fetchRunningCommission(selectedManager); // Fetch running commission data for selected manager
      fetchCommissionPayments(); // Fetch finalized commission data for selected manager
    }
  }, [selectedManager, fetchRunningCommission, fetchCommissionPayments]);

  useEffect(() => {
    const fetchLostFirstSession = async () => {
      try {
        // Retrieve the token from localStorage
        const token = localStorage.getItem("token");

        if (!token) {
          console.error("No token found, redirecting to login");
          return;
        }

        const response = await axios.get("/api/services", {
          headers: {
            Authorization: `Bearer ${token}`, // Ensure the token is correct
          },
          params: { status: "finished" },
        });

        console.log("API Response for /api/services:", response.data); // Log the API response

        if (Array.isArray(response.data)) {
          const lostFirstSession = response.data.filter(
            (service) => service.completed_lessons === 1
          );
          setLostFirstSessionCount(lostFirstSession.length);
        } else {
          console.error(
            "Expected an array but got something else:",
            response.data
          );
        }
      } catch (error) {
        console.error("Error fetching lost first session data:", error);
      }
    };

    fetchLostFirstSession();
  }, []);

  const [dateRange, setDateRange] = useState({
    startDate: dayjs().startOf("month"),
    endDate: dayjs().endOf("month"),
  });

  useEffect(() => {
    const fetchLoggedInUser = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found, redirecting to login");
          return;
        }

        // Fetch current user information
        const response = await axios.get("/api/current-user", {
          headers: { Authorization: `Bearer ${token}` },
        });

        setLoggedInUser(response.data);

        // If the logged-in user is a Client Manager, get their Enquiry data
        if (response.data.role === "Client Manager") {
          // Fetch data for enquiries/jobs where this Client Manager is also the Sales Manager
          const salesManagerEnquiriesResponse = await axios.get(
            "/api/sales-managers-for-enquiry",
            {
              params: { clientManagerEmail: response.data.email }, // Updated to use enquiry-based data
            }
          );

          // Assuming the response contains relevant enquiries/jobs
          if (salesManagerEnquiriesResponse.data.length > 0) {
            setSelectedManager(response.data.email); // Set the logged-in user as selected manager for viewing
            fetchYearlyCommission(response.data.email);
          } else {
            console.warn(
              "No enquiries or jobs found where this Client Manager is the Sales Manager."
            );
          }
        }
      } catch (error) {
        console.error(
          "Error fetching logged-in user or associated sales manager data:",
          error
        );
      }
    };

    fetchLoggedInUser();
  }, []);

  const handleManagerChange = (event) => {
    const managerEmail = event.target.value;
    setSelectedManager(managerEmail);

    // Clear current monthly commission state before fetching new data
    setMonthlyCommission([]); // Clear stale data

    fetchYearlyCommission(managerEmail); // Fetch the new manager's data
  };

  const fetchLeaderboardData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get("/api/leaderboard", {
        params: {
          startDate: dateRange.startDate.toISOString(),
          endDate: dateRange.endDate.toISOString(),
          role: effectiveRole, // ✅ Pass role to API for filtered data

        },
      });
      setsalesManagers(response.data);
    } catch (error) {
      console.error("Error fetching leaderboard data:", error.message);
    } finally {
      setLoading(false);
    }
  }, [dateRange, effectiveRole]);
  

  const handleDateRangeChange = (newValue) => {
    if (newValue && newValue[0] && newValue[1]) {
      setDateRange({
        startDate: newValue[0].startOf("day"),
        endDate: newValue[1].endOf("day"),
      });
    }
  };

  const getActiveManagers = (key) => {
    return salesManagers.filter((managerData) => {
      // If the manager is inactive AND the stat is 0, skip them.
      if (managerData.is_active === false && (managerData[key] || 0) === 0) {
        return false;
      }
      // Otherwise, include the manager.
      return true;
    });
  };
  
  


  const fetchYearlyCommission = useCallback(async (managerEmail) => {
    if (!managerEmail) return;
    setLoading(true);
    try {
      // Update endpoint to reflect enquiry-level commission
      const response = await axios.get(
        `/commission/yearly/enquiry/${managerEmail}`
      );
      console.log("Commission data:", response.data); // Log response to verify

      // Process the response to create aggregated commission data
      const aggregatedCommission = response.data.reduce((acc, current) => {
        let { month_year, total_commission } = current;

        // Convert MM/YYYY to YYYY-MM for sorting
        const formattedMonthYear = dayjs(month_year, "MM/YYYY").format(
          "YYYY-MM"
        );

        if (!formattedMonthYear || total_commission == null) {
          return acc;
        }

        const existingEntry = acc.find(
          (item) => item.month_year === formattedMonthYear
        );
        if (existingEntry) {
          existingEntry.total_commission += parseFloat(total_commission);
        } else {
          acc.push({
            month_year: formattedMonthYear,
            total_commission: parseFloat(total_commission) || 0,
          });
        }

        return acc;
      }, []);

      setMonthlyCommission(aggregatedCommission || []);
    } catch (error) {
      console.error("Error fetching yearly commission:", error);
      setMonthlyCommission([]);
    } finally {
      setLoading(false);
    }
  }, []);

  // Fetch appointment data for the month
  const fetchAppointmentsForMonth = async (monthYear, managerEmail) => {
    try {
      const response = await axios.get(`/appointments/month/${monthYear}`, {
        params: { manager: managerEmail }, // Ensure the query uses the correct manager (linked to enquiries)
      });
      console.log("Appointments fetched for month:", monthYear, response.data);
      return response.data; // Assumes API returns appointment data for the given month for that manager
    } catch (error) {
      console.error("Error fetching appointments:", error);
      return [];
    }
  };

  // const fetchCommissionPayments = useCallback(async () => {
  //   try {
  //     const response = await axios.get("/api/commission-payments", {
  //       params: { manager: selectedManager },
  //     });

  //     // Log fetched commission data
  //     console.log("Fetched commission data:", response.data);

  //     // Update commission state from the backend, including the status
  //     const updatedCommission = response.data.map((commission) => ({
  //       month_year: commission.month_year,
  //       total_commission: parseFloat(commission.total_commission) || 0,
  //       paidStatus: commission.status === 'Paid' ? 'Paid' : 'Unpaid', // Use backend's status field
  //       payment_date: commission.payment_date // Ensure this field is returned from the backend
  //     }));

  //     setMonthlyCommission(updatedCommission);
  //   } catch (error) {
  //     console.error("Error fetching commission payments:", error);
  //   }
  // }, [selectedManager]);

  //  // Toggle the expanded row to show or hide the related appointments
  //  const toggleRowExpanded = (monthYear) => {
  //   setExpandedRows((prev) => ({
  //     ...prev,
  //     [monthYear]: !prev[monthYear],
  //   }));
  // };

  // const toggleRowExpanded = (uniqueRowId) => {
  //   setExpandedRows((prevExpandedRows) => ({
  //     ...prevExpandedRows,
  //     [uniqueRowId]: !prevExpandedRows[uniqueRowId],
  //   }));
  // };

  // Ensure that useEffect comes after the fetchCommissionPayments definition
  useEffect(() => {
    if (selectedManager) {
      fetchCommissionPayments();
      console.log("Monthly Commission:", monthlyCommission); // Log the commission data to debug
    }
  }, [fetchCommissionPayments, selectedManager]);

  // const handleTogglePaidStatus = async (commissionData) => {
  //   try {
  //     const currentStatus = commissionData.paidStatus === "Paid" ? "Unpaid" : "Paid";
  //     const finalAmount = commissionData.total_commission || 0;

  //     // Log the data being sent
  //     console.log("Month being sent to backend:", commissionData.month_year);

  //     const payload = {
  //       manager: selectedManager,
  //       status: currentStatus,
  //       amount: finalAmount,
  //       month: commissionData.month_year,  // Ensure this is in MM/YYYY format
  //     };

  //     console.log("Payload being sent to backend:", payload);

  //     await axios.post(`/api/update-commission-status`, payload);

  //     // Update the UI state
  //     setMonthlyCommission((prev) =>
  //       prev.map((item) =>
  //         item.month_year === commissionData.month_year
  //           ? { ...item, paidStatus: currentStatus }
  //           : item
  //       )
  //     );
  //   } catch (error) {
  //     console.error("Error updating commission status:", error.response ? error.response.data : error.message);
  //   }
  // };

  const handleTogglePaidStatus = async (commissionData) => {
    try {
      // Toggle between 'Paid' and 'Unpaid'
      const newStatus =
        commissionData.paidStatus === "Paid" ? "Unpaid" : "Paid";
      const paymentDate = newStatus === "Paid" ? dayjs().toISOString() : null;

      const payload = {
        manager: selectedManager,
        status: newStatus,
        amount: commissionData.total_commission,
        month: commissionData.month_year,
        payment_date: paymentDate,
      };

      // Make an API call to update commission status
      await axios.post(`/api/update-commission-status`, payload);

      // Update the local state to reflect the change in UI
      setMonthlyCommission((prev) =>
        prev.map((item) =>
          item.month_year === commissionData.month_year
            ? { ...item, paidStatus: newStatus, payment_date: paymentDate }
            : item
        )
      );
    } catch (error) {
      console.error("Error updating commission status:", error);
    }
  };

  useEffect(() => {
    const fetchYearlyCommission = async (managerEmail) => {
      if (!managerEmail) return;
      setLoading(true);
      try {
        const response = await axios.get(`/commission/yearly/${managerEmail}`);
        console.log("Commission data:", response.data); // Log response here

        const aggregatedCommission = response.data.reduce((acc, current) => {
          const { month_year, total_commission } = current;
          const existingEntry = acc.find(
            (item) => item.month_year === month_year
          );
          if (existingEntry) {
            existingEntry.total_commission += parseFloat(total_commission);
          } else {
            acc.push({
              month_year,
              total_commission: parseFloat(total_commission),
            });
          }
          return acc;
        }, []);

        setMonthlyCommission(aggregatedCommission || []);
      } catch (error) {
        console.error("Error fetching yearly commission:", error);
        setMonthlyCommission([]);
      } finally {
        setLoading(false);
      }
    };

    if (selectedManager) {
      fetchYearlyCommission(selectedManager);
    }
  }, [selectedManager]);

  useEffect(() => {
    const saveUnpaidMonths = async () => {
      try {
        console.log("Saving unpaid months for manager:", selectedManager);

        // Filter only months that don't have a paid status before saving them as unpaid
        const unpaidMonths = monthlyCommission
          .filter((item) => item.paidStatus !== "Paid")
          .map((item) => item.month_year);

        if (unpaidMonths.length > 0) {
          const response = await axios.post("/api/save-unpaid-entries", {
            manager: selectedManager,
            months: unpaidMonths,
          });

          console.log("Unpaid months saved:", response.data);

          // Re-fetch commission data after saving unpaid months to ensure correct state
          fetchCommissionPayments();
        }
      } catch (error) {
        console.error("Error saving unpaid months:", error);
      }
    };

    if (selectedManager) {
      saveUnpaidMonths();
    }
  }, [selectedManager, fetchCommissionPayments]);

  // // Conditionally show the commission table based on user role
  // // Conditionally show the commission table based on user role
  // const showCommissionTable = () => {
  //   if (effectiveRole === "Admin") {
  //     // Admin can select any Sales Manager
  //     return (
  //       <div>
  //         <div className="mb-4 mt-4">
  //           <label htmlFor="salesManager" className="block text-sm font-medium text-gray-700">
  //             Select Sales Manager
  //           </label>
  //           <select
  //             id="salesManager"
  //             name="salesManager"
  //             className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
  //             value={selectedManager || ""}
  //             onChange={handleManagerChange}
  //           >
  //             <option value="">Select Manager</option>
  //             {salesManagers.map((managerData) => (
  //               <option key={managerData.id} value={managerData.manager}>
  //                 {managerData.manager_first_name || "Unknown"} {managerData.last_name || ""}
  //               </option>
  //             ))}
  //           </select>
  //         </div>
  //         {selectedManager && renderCommissionTable()}
  //       </div>
  //     );
  //   } else if (effectiveRole === "Client Manager" && selectedManager) {
  //     // Client Manager can view the commission table if they are the Sales Manager for any clients
  //     return renderCommissionTable();
  //   }
  //   return null;
  // };

  // // Modify `renderCommissionTable` to use a proper unique identifier for each row:
  // const renderCommissionTable = () => {
  //   // Sort finalized commission from newest to oldest
  //   const sortedFinalizedCommission = [...finalizedCommission].sort((a, b) => {
  //     const dateA = new Date(a.month_year);
  //     const dateB = new Date(b.month_year);
  //     return dateB - dateA; // Sort in descending order
  //   });

  //   return (
  //     <div className="w-full overflow-hidden rounded-xl border border-gray-200 mb-6 mt-6">
  //       <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
  //         <div className="text-lg font-medium leading-6 text-gray-900">
  //           {effectiveRole === "Admin" ? "Commission" : `My Commission (${loggedInUser?.first_name || ""})`}
  //         </div>
  //       </div>
  //       <div className="overflow-x-auto">
  //         <table className="min-w-full divide-y divide-gray-200">
  //           <thead className="bg-gray-50">
  //             <tr>
  //               <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Month</th>
  //               <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Commission</th>
  //               <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
  //               <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Details</th>
  //             </tr>
  //           </thead>
  //           <tbody className="bg-white divide-y divide-gray-200">
  //             {/* Render running commission row for the current month */}
  //             {runningCommission.length > 0 &&
  //               runningCommission.map((commissionData, index) => {
  //                 const uniqueRowId = `running-${commissionData.month_year}-${index}`;
  //                 return (
  //                   <tr key={uniqueRowId}>
  //                     <td className="px-6 py-4 text-gray-700">{commissionData.month_year || 'Current Month'}</td>
  //                     <td className="px-6 py-4 text-gray-700">£{parseFloat(commissionData.total_commission).toFixed(2)}</td>
  //                     <td className="px-6 py-4">Cannot mark paid until end of month</td>
  //                     <td className="px-6 py-4">
  //                       <button
  //                         onClick={() => toggleRowExpanded(uniqueRowId, commissionData)}
  //                         className="text-indigo-600 hover:text-indigo-900"
  //                       >
  //                         {expandedRows[uniqueRowId] ? 'Hide' : 'Show'} Details
  //                       </button>
  //                     </td>
  //                   </tr>
  //                 );
  //               })}

  //             {/* Render finalized commission rows */}
  //             {sortedFinalizedCommission.map((commissionData, index) => {
  //               const uniqueRowId = `${commissionData.month_year}-${index}`;

  //               // Parsing month_year from the commission data
  //               const commissionDate = new Date(commissionData.month_year);
  //               // Getting the current date at the start of the month for comparison
  //               const currentDate = new Date();
  //               currentDate.setDate(1); // Set to the first day of the current month
  //               currentDate.setHours(0, 0, 0, 0);

  //               // Check if commission is from the current month
  //               const isCurrentMonth = commissionDate.getFullYear() === currentDate.getFullYear() && commissionDate.getMonth() === currentDate.getMonth();
  //               // Check if commission is from a past month
  //               const isPastMonth = commissionDate < currentDate;

  //               return (
  //                 <React.Fragment key={uniqueRowId}>
  //                   <tr>
  //                     <td className="px-6 py-4 text-gray-700">
  //                       {commissionData.month_year || 'Unknown Month'}
  //                     </td>
  //                     <td className="px-6 py-4 text-gray-700">
  //                       £{parseFloat(commissionData.total_commission).toFixed(2)}
  //                     </td>
  //                     <td className="px-6 py-4">
  //                       {commissionData.paidStatus === 'Paid' ? (
  //                         <span className="text-green-700 bg-green-50 ring-green-600/20 ml-4 px-2 py-1 rounded text-xs font-medium">
  //                           Paid on{' '}
  //                           {commissionData.payment_date
  //                             ? new Date(commissionData.payment_date).toLocaleDateString('en-GB', {
  //                                 year: 'numeric',
  //                                 month: 'short',
  //                                 day: '2-digit',
  //                               })
  //                             : 'Invalid Date'}
  //                         </span>
  //                       ) : (
  //                         <>
  //                           {isCurrentMonth ? (
  //                             <span className="text-gray-500">
  //                               Cannot mark paid until the end of the month
  //                             </span>
  //                           ) : isPastMonth ? (
  //                             <button
  //                               onClick={() => handleTogglePaidStatus(commissionData)}
  //                               className="text-blue-700 bg-blue-50 ring-blue-600/10 ml-4 px-2 py-1 rounded text-xs font-medium"
  //                             >
  //                               Mark as Paid
  //                             </button>
  //                           ) : (
  //                             <span className="text-gray-500">
  //                               Cannot mark paid for future months
  //                             </span>
  //                           )}
  //                         </>
  //                       )}
  //                     </td>
  //                     <td className="px-6 py-4">
  //                       <button
  //                         onClick={() => toggleRowExpanded(uniqueRowId, commissionData)}
  //                         className="text-indigo-600 hover:text-indigo-900"
  //                       >
  //                         {expandedRows[uniqueRowId] ? 'Hide' : 'Show'} Details
  //                       </button>
  //                     </td>
  //                   </tr>
  //                   {expandedRows[uniqueRowId] && (
  //                     <tr>
  //                       <td colSpan="4" className="px-6 py-4 bg-gray-50">
  //                         <AppointmentsSubTable
  //                           appointments={commissionData.appointments || []}
  //                           totalCommission={commissionData.total_commission || 0}
  //                         />
  //                       </td>
  //                     </tr>
  //                   )}
  //                 </React.Fragment>
  //               );
  //             })}
  //           </tbody>
  //         </table>
  //       </div>
  //     </div>
  //   );
  // };

  // const toggleRowExpanded = async (uniqueRowId, commissionData) => {
  //   if (!expandedRows[uniqueRowId]) {
  //     try {
  //       // Only fetch if appointments are not defined or empty
  //       if (!commissionData.appointments || commissionData.appointments.length === 0) {
  //         const appointments = await fetchAppointmentsForMonth(commissionData.month_year, selectedManager);
  //         setMonthlyCommission((prev) =>
  //           prev.map((item) =>
  //             item.month_year === commissionData.month_year
  //               ? { ...item, appointments }  // Update the appointments in the corresponding item
  //               : item
  //           )
  //         );
  //       }
  //     } catch (error) {
  //       console.error("Error fetching appointments:", error);
  //     }
  //   }

  //   // Set the expanded row state to have only the current row expanded.
  //   setExpandedRows({
  //     [uniqueRowId]: !expandedRows[uniqueRowId]
  //   });
  // };

  const isWholeMonth = (startDate, endDate) => {
    return (
      startDate.date() === 1 && endDate.isSame(startDate.endOf("month"), "day")
    );
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const generateMonthsForYear = (year = dayjs().year()) => {
    const monthsArray = [];
    const currentDate = dayjs(); // Current date

    for (let month = 0; month < 12; month++) {
      const monthStart = dayjs()
        .year(year)
        .month(month)
        .startOf("month")
        .format("YYYY-MM"); // Use 'YYYY-MM' format
      if (monthStart <= currentDate.format("YYYY-MM")) {
        monthsArray.push(monthStart);
      }
    }

    return monthsArray;
  };

  useEffect(() => {
    console.log("Sales Managers Data:", salesManagers); // Log the entire salesManagers array
  }, [salesManagers]);

  useEffect(() => {
    fetchLeaderboardData();
  }, [effectiveRole, dateRange]);


  useEffect(() => {
    fetchLeaderboardData();
  }, [fetchLeaderboardData]);

  // Helper function to dynamically assign status styles
  const getStatusClass = (status) => {
    return status === "Paid" ? statuses.Paid : statuses.Unpaid;
  };

  return (
    <div className="mx-auto p-4">
      <h1 className="mt-3 text-3xl font-bold tracking-tight text-slate-900 mb-4">
        Leaderboard
      </h1>
    
      <button
        onClick={() => setShowDatePicker((prev) => !prev)}
        className="px-4 py-2 border rounded mb-4"
      >
        {dateRange.startDate.format("MMM DD, YYYY")} -{" "}
        {dateRange.endDate.format("MMM DD, YYYY")}
      </button>

      <Popover
        open={showDatePicker}
        anchorEl={anchorEl}
        onClose={() => setShowDatePicker(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className="p-4">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDateRangePicker
              displayStaticWrapperAs="desktop"
              value={[dateRange.startDate, dateRange.endDate]}
              onChange={handleDateRangeChange}
              slotProps={{
                shortcuts: {
                  items: shortcutsItems,
                },
                actionBar: { actions: [] },
              }}
              calendars={2}
              dayOfWeekFormatter={(dayIndex) => {
                const startFromMondayIndex = (dayIndex + 1) % 7; // Adjust day index to start from Monday
                return dayjs().weekday(startFromMondayIndex).format("dd");
              }}
            />
          </LocalizationProvider>
        </div>
      </Popover>

      {loading ? (
        <div className="text-center">
          <p className="text-xl font-semibold">Loading leaderboard...</p>
        </div>
      ) : (
        <>
          {/* First Row: Connected, First Lesson Complete, Won, Packages */}
          <ul className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-2 xl:grid-cols-2 mt-6">
          {firstRowCards.map(({ title, key, image }) => (
  <li
    key={title}
    className="overflow-hidden rounded-2xl border border-black-300 shadow-lg bg-gradient-to-r from-blue-50 to-blue-100"
  >
    <div className="flex items-center gap-x-6 border-b border-black-900/10 bg-blue-200 p-8">
      <img
        alt={title}
        src={image}
        className="h-16 w-16 flex-none rounded-lg object-cover"
      />
      <div className="text-2xl font-semibold text-black-900">{title}</div>
    </div>
    <dl className="-my-3 divide-y divide-black-100 px-8 py-6 text-xl font-bold leading-6 text-black-900">
       
          {getActiveManagers(key)
        .sort((a, b) => (b[key] || 0) - (a[key] || 0))
        .map((managerData) => (
    <div
      key={managerData.manager}
      className="flex justify-between gap-x-4 py-3"
    >
    <dt className="flex items-center gap-x-2 text-black-500" style={{ width: '150px' }}>
        <img
          src={managerData.profile_image}
          alt={managerData.manager_first_name}
          className="h-6 w-6 rounded-full object-cover"
        />
        {managerData.manager_first_name || managerData.manager}
      </dt>
      
      <dd className="text-black-700">
        {key === "lostFirstSession"
          ? (console.log(
              `Lost First Session count for manager ${managerData.manager}:`,
              managerData.lostFirstSession
            ),
            managerData.lostFirstSession)
          : managerData[key] || 0}
      </dd>

      <dd className="text-black-700">
        {effectiveRole === "Admin" && (
          <Link
            to={(() => {
              // Define the base URL
              const baseUrl = "/sales-book?";
              
              // Create a new URLSearchParams instance for better URL handling
              const params = new URLSearchParams({
                clientManagerFilter: '',
                firstLessonPlannedFilter: '',
                salesManagerFilter: managerData.manager_first_name || '', // Use sales manager's name
                startDate: dateRange.startDate.toISOString(), // Pass the start date
                endDate: dateRange.endDate.toISOString(), // Pass the end date
                
              });

              // Set specific parameters based on the card title
              switch (title) {
                case "Connected":
                  params.set('milestoneFilter', 'No Lessons Complete');
                  params.set('tab', 'connected');
                  break;
                case "First Lesson Complete":
                  params.set('milestoneFilter', 'First Lesson Complete');
                  params.set('tab', 'connected');
                  break;
                case "Won":
                  params.set('tab', 'won');
                  break;
                  case "Packages":
  params.set("packageFilter", "Yes"); // This is key
  params.set("tab", "won");
  break;

                case "Lost - First Session":
                  params.set('jobStatusFilter', 'finished');
                  params.set('milestoneFilter', 'First Lesson Complete');
                  params.set('tab', 'lost');
                  break;
                case "Lost - No Session":
                  params.set('jobStatusFilter', 'finished');
                  params.set('milestoneFilter', 'No Lessons Complete');
                  params.set('tab', 'lost');
                  break;
                default:
                  // No additional filters for unknown title
                  break;
              }

              // Return the full URL with all parameters
              return `${baseUrl}${params.toString()}`;
            })()}
            className="px-2 py-1 text-xs font-semibold text-blue-700 bg-white rounded"
          >
            View in Sales Book
          </Link>
        )}
      </dd>
    </div>
  ))}


      </dl>
    </li>
  ))}
</ul>


 
<ul className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-2 xl:gap-x-8 mt-6">
  {secondRowCards.map(({ title, key, image, subtitle }) => (
    <li
      key={title}
      className="overflow-hidden rounded-xl border border-gray-200"
    >
      <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
        <img
          alt={title}
          src={image}
          className="h-12 w-12 flex-none rounded-lg object-cover"
        />
        <div>
          <div className="text-lg font-medium leading-6 text-gray-900">
            {title}
          </div>
          <div className="text-sm text-gray-500">{subtitle}</div>
        </div>
      </div>
      <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
        {getActiveManagers(key)
          .sort((a, b) => (b[key] || 0) - (a[key] || 0))
          .map((managerData) => (
            <div
              key={managerData.manager}
              className="flex justify-between gap-x-4 py-3"
            >
              <dt
                className="flex items-center gap-x-2 text-gray-500"
                style={{ width: "150px" }}
              >
                <img
                  src={managerData.profile_image}
                  alt={managerData.manager_first_name}
                  className="h-6 w-6 rounded-full object-cover"
                />
                {managerData.manager_first_name || managerData.manager}
              </dt>
              <dd className="text-gray-700">
                {managerData[key] || 0}
              </dd>
              <dd className="text-gray-700">
                {effectiveRole === "Admin" && (
                  <Link
                    // You can also add an onClick handler if you want to update state,
                    // e.g., setTab("connected") for these cards.
                    to={(() => {
                      const baseUrl = "/sales-book?";
                      // Create the search parameters with defaults.
                      const params = new URLSearchParams({
                        clientManagerFilter: "",
                        firstLessonPlannedFilter: "",
                        salesManagerFilter:
                          managerData.manager_first_name || "",
                        startDate: dateRange.startDate.toISOString(),
                        endDate: dateRange.endDate.toISOString(),
                      });
                      // Set extra parameters based on the card title:
                      switch (title) {
                        case "Connected":
                          params.set('milestoneFilter', 'No Lessons Complete');
                          params.set('tab', 'connected');
                          break;
                          case "First Lesson Complete":
                            params.set('milestoneFilter', 'First Lesson Complete');
                            params.set('tab', 'connected');
                            break;
                        case "Lost - First Session":
                          params.set("jobStatusFilter", "finished");
                          params.set("milestoneFilter", "First Lesson Complete");
                          params.set("tab", "lost");
                          break;
                        case "Lost - No Session":
                          params.set("jobStatusFilter", "finished");
                          params.set("milestoneFilter", "No Lessons Complete");
                          params.set("tab", "lost");
                          break;
                        default:
                          break;
                      }
                      return `${baseUrl}${params.toString()}`;
                    })()}
                    className="px-2 py-1 text-xs font-semibold text-blue-700 bg-white rounded"
                  >
                    View in Sales Book
                  </Link>
                )}
              </dd>
            </div>
          ))}
      </dl>
    </li>
  ))}
</ul>



{/* Third Row: Lost - No Session, Lost - First Session */}
<ul className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-2 xl:gap-x-8 mt-6">
  {thirdRowCards.map(({ title, key, image, subtitle }) => (
    <li
      key={title}
      className="overflow-hidden rounded-xl border border-gray-200"
    >
      <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
        <img
          alt={title}
          src={image}
          className="h-12 w-12 flex-none rounded-lg object-cover"
        />
        <div>
          <div className="text-lg font-medium leading-6 text-gray-900">
            {title}
          </div>
          <div className="text-sm text-gray-500">{subtitle}</div>
        </div>
      </div>
      <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
        {getActiveManagers(key)
        .sort((a, b) => (b[key] || 0) - (a[key] || 0))
        .map((managerData) => (
            <div
              key={managerData.manager}
              className="flex justify-between gap-x-4 py-3"
            >
              <dt className="flex items-center gap-x-2 text-gray-500" style={{ width: '150px' }}>
                <img
                  src={managerData.profile_image}
                  alt={managerData.manager_first_name}
                  className="h-6 w-6 rounded-full object-cover"
                />
                {managerData.manager_first_name || managerData.manager}
              </dt>

              <dd className="text-gray-700">
                {managerData[key] || 0}
              </dd>

              <dd className="text-gray-700">
                {effectiveRole === "Admin" && (
                  <Link
                    to={(() => {
                      const baseUrl = "/sales-book?";
                      const params = new URLSearchParams({
                        clientManagerFilter: "",
                        firstLessonPlannedFilter: "",
                        salesManagerFilter: managerData.manager_first_name || "",
                        startDate: dateRange.startDate.toISOString(),
                        endDate: dateRange.endDate.toISOString(),
                      });

                      switch (title) {
                        case "Lost - No Session":
                          params.set("jobStatusFilter", "finished");
                          params.set("milestoneFilter", "No Lessons Complete");
                          params.set("tab", "lost");
                          break;
                        case "Lost - First Session":
                          params.set("jobStatusFilter", "finished");
                          params.set("milestoneFilter", "First Lesson Complete");
                          params.set("tab", "lost");
                          break;
                        default:
                          break;
                      }

                      return `${baseUrl}${params.toString()}`;
                    })()}
                    className="px-2 py-1 text-xs font-semibold text-blue-700 bg-blue-100 rounded"
                  >
                    View in Sales Book
                  </Link>
                )}
              </dd>
            </div>
          ))}
      </dl>
    </li>
  ))}
</ul>


        </>
      )}
      {/* 
{showCommissionTable()} */}
    </div>
  );
};

// Appointments Sub Table
const AppointmentsSubTable = ({ appointments = [], totalCommission = 0 }) => {
  console.log("Appointments received by AppointmentsSubTable:", appointments); // Debugging log to see if appointments are actually received

  // Ensure that appointments is an array before proceeding
  if (!Array.isArray(appointments)) {
    console.error(
      "Expected appointments to be an array but received:",
      typeof appointments
    );
    return <div>No valid appointments available for this month.</div>;
  }

  if (appointments.length === 0) {
    // Check if there is a total commission, but no appointments
    if (totalCommission > 0) {
      return (
        <div>
          No appointments available for this month. The commission is calculated
          from package sales or other non-appointment sources.
        </div>
      );
    }
    return <div>No valid appointments available for this month.</div>;
  }

  return (
    <table className="min-w-full divide-y divide-gray-200 bg-gray-50">
      <thead>
        <tr>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Appointment ID
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Service ID
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Start
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Finish
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Duration (hours)
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Total Commission (£)
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {appointments.map((appointment) => (
          <tr key={appointment.appointment_id}>
            <td className="px-6 py-4">{appointment.appointment_id}</td>
            <td className="px-6 py-4">{appointment.service_id}</td>
            <td className="px-6 py-4">
              {new Date(appointment.start).toLocaleString()}
            </td>
            <td className="px-6 py-4">
              {new Date(appointment.finish).toLocaleString()}
            </td>
            <td className="px-6 py-4">{appointment.duration_hours}</td>
            <td className="px-6 py-4">
              {parseFloat(appointment.commission).toFixed(2)}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Leaderboard;
