import React, { useState, useEffect, useContext } from "react";
import { useLocation, Route, Routes, useNavigate } from "react-router-dom";
import axios from "axios";
import { LicenseInfo } from "@mui/x-license";

import Pipeline from "./components/Pipeline";
import Tutors from "./components/Tutors";
import Users from "./components/Users";
import ProposalBuilder from "./components/ProposalBuilder";
import ClientDetails from "./components/ClientDetails";
import ClientList from "./components/ClientList";
import Leaderboard from "./components/Leaderboard";
import SalesBook from "./components/SalesBook";
import Commissions from "./components/Commissions";
import Login from "./components/Login";
import Logout from "./components/Logout";
import PrivateRoute from "./PrivateRoute";
import Layout from "./Layout";
import NotesTab from "./components/NotesTab";
import UserProfile from "./components/UserProfile";
import { RoleProvider, RoleContext } from "./RoleContext"; // Import Role Context

import {
  ArchiveBoxArrowDownIcon,
  BookOpenIcon,
  UsersIcon,
  CurrencyDollarIcon,
  StarIcon,
  UserCircleIcon,
} from "@heroicons/react/20/solid";

LicenseInfo.setLicenseKey(
  "397110379bbdb9d8dcd4b7e2a9e5533dTz05OTQ3NSxFPTE3NTk5MDg0MDQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPVEzLTIwMjQsS1Y9Mg=="
);

const App = () => {
  return (
    <RoleProvider> {/* ✅ Ensure the provider wraps the app */}
      <AppContent />
    </RoleProvider>
  );
};

const AppContent = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { userRole, setUserRole } = useContext(RoleContext);

  // 🔹 Define navigation state
  const [navigation, setNavigation] = useState([
    { name: "Pipeline", href: "/", icon: ArchiveBoxArrowDownIcon, current: false },
    { name: "Sales Book", href: "/sales-book", icon: BookOpenIcon, current: false },
    { name: "Leaderboard", href: "/leaderboard", icon: StarIcon, current: false },
    { name: "Commissions", href: "/commissions", icon: CurrencyDollarIcon, current: false },
    { name: "Customers", href: "/clients", icon: UsersIcon, current: false },
    { name: "Users", href: "/users", icon: UserCircleIcon, current: false },
  ]);

   // 🔹 Function to update the active navigation item
   const handleNavigationClick = (pageName) => {
    setNavigation((prevNav) =>
      prevNav.map((item) => ({
        ...item,
        current: item.name === pageName, // Set active page
      }))
    );
  };

  // 🔹 Check authentication on page load
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsAuthenticated(true);
      fetchCurrentUser();
    }
  }, []);

  // 🔹 Fetch user role
  const fetchCurrentUser = async () => {
    const token = localStorage.getItem("token");
    if (!token) return;
  
    try {
      const response = await axios.get("/api/current-user", {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data.role) {
        setUserRole(response.data.role); // ✅ Set user role in context
        localStorage.setItem("userRole", response.data.role); // ✅ Persist in localStorage
      }
    } catch (error) {
      console.error("Error fetching user role:", error);
    }
  };
  

  // 🔹 Logout Handler
  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
    navigate("/login");
  };

  // 🔹 Update Active Navigation Item
  const updatedNavigation = navigation.map((item) => ({
    ...item,
    current: item.href === location.pathname,
  }));

  return (
    <div>
      {isAuthenticated ? (
        <Layout
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        handleLogout={handleLogout}
        handleNavigationClick={handleNavigationClick} // ✅ Pass function to Layout
        navigation={updatedNavigation} // ✅ Pass updated navigation state
        userRole={userRole}
      >
      
          <Routes>
            <Route
              path="/"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <Pipeline />
                </PrivateRoute>
              }
            />
            <Route
              path="/notes"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <NotesTab />
                </PrivateRoute>
              }
            />
            <Route
              path="/tutors"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <Tutors />
                </PrivateRoute>
              }
            />
            <Route
              path="/users"
              element={
                ["Admin", "Assistant"].includes(userRole) ? (
                  <PrivateRoute isAuthenticated={isAuthenticated}>
                    <Users />
                  </PrivateRoute>
                ) : (
                  <div>Access Denied - Refresh page if logged in as an admin</div>
                )
              }
            />
            <Route
              path="/proposal-builder"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <ProposalBuilder />
                </PrivateRoute>
              }
            />
            <Route
              path="/clients/:clientId"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <ClientDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/clients"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <ClientList />
                </PrivateRoute>
              }
            />
            <Route
              path="/leaderboard"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <Leaderboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/commissions"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <Commissions />
                </PrivateRoute>
              }
            />
            <Route
              path="/sales-book"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <SalesBook />
                </PrivateRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <UserProfile />
                </PrivateRoute>
              }
            />
          </Routes>
        </Layout>
      ) : (
        <Routes>
          <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
          <Route path="/logout" element={<Logout setIsAuthenticated={setIsAuthenticated} />} />
          <Route path="/" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
        </Routes>
      )}
    </div>
  );
};

export default App;
