import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";

import { useContext } from "react";
import { RoleContext } from "../RoleContext"; // Adjust the path as needed


const Commissions = () => {
  const [loading, setLoading] = useState(true);
  // const [role, setRole] = useState(""); // Store the role of logged-in user
  const [currentMonthCommission, setCurrentMonthCommission] = useState(null);
  const [commissionHistory, setCommissionHistory] = useState([]);
  const [salesManagers, setSalesManagers] = useState([]);
  const [selectedManager, setSelectedManager] = useState("");
  const [expandedRows, setExpandedRows] = useState({}); // Track expanded rows for appointments or packages
  const [details, setDetails] = useState({}); // Store details by key (managerId-monthYear)

  
const { effectiveRole } = useContext(RoleContext); // ✅ Get role from context


useEffect(() => {
  const fetchLoggedInUser = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found, redirecting to login");
        return;
      }
      const response = await axios.get("/api/current-user", {
        headers: { Authorization: `Bearer ${token}` },
      });
      // Remove setRole since we no longer use local role state

      // Calculate commissions and update the database
      await calculateFinalCommissions();

      // Use effectiveRole from context for conditional logic
      if (effectiveRole === "Admin") {
        await fetchSalesManagers();
      } else if (effectiveRole === "Client Manager") {
        setSelectedManager(response.data.email);
        fetchCommissions(response.data.email);
      }
    } catch (error) {
      console.error("Error fetching logged-in user:", error);
    }
  };

  fetchLoggedInUser();
}, [effectiveRole]);


 // Calculate final commissions and save them in the database
 const calculateFinalCommissions = async () => {
  try {
    await axios.post("/api/calculate-final-commissions");
    console.log("Final commissions calculated and updated.");
  } catch (error) {
    console.error("Error calculating final commissions:", error);
  }
};

// Fetch all sales managers for the Admin role
const fetchSalesManagers = async () => {
  try {
    const response = await axios.get("/api/sales-managers");
    setSalesManagers(response.data);
  } catch (error) {
    console.error("Error fetching sales managers:", error);
  }
};

    // Fetch commissions and related counts for the selected manager or logged-in manager
    const fetchCommissions = useCallback(async (managerEmail) => {
      setLoading(true);
      try {
        const response = await axios.get("/api/admin/commissions", {
          params: { manager_id: managerEmail || selectedManager },
          headers: {
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "0",
          },
        });
    
        if (response) {
          const commissionData = Array.isArray(response.data) ? response.data : [];
          
          // Sort commissions so that the most recent month comes first
          commissionData.sort((a, b) => {
            const [aMonth, aYear] = a.month.split("/");
            const [bMonth, bYear] = b.month.split("/");
            const aYearNum = parseInt(aYear, 10);
            const bYearNum = parseInt(bYear, 10);
            
            // Compare years first: descending (newest year first)
            if (aYearNum !== bYearNum) {
              return bYearNum - aYearNum;
            }
            
            // If years are the same, compare months: descending (newest month first)
            return parseInt(bMonth, 10) - parseInt(aMonth, 10);
          });
                    
          setCommissionHistory(commissionData);
    

        // Fetch counts for appointments and packages
        const newDetails = {};
        for (let commission of commissionData) {
          const { client_manager_email, month } = commission;

          const appointmentResponse = await axios.get("/api/admin/appointments", {
            params: { manager_id: client_manager_email, month_year: month },
          });

          const packageResponse = await axios.get("/api/admin/packages", {
            params: { manager_id: client_manager_email, month_year: month },
          });

          const rowKeyAppointments = `${client_manager_email}-${month}-appointments`;
          const rowKeyPackages = `${client_manager_email}-${month}-packages`;

          newDetails[rowKeyAppointments] = {
            type: "appointments",
            data: appointmentResponse.data,
            count: appointmentResponse.data.length,
          };
          newDetails[rowKeyPackages] = {
            type: "packages",
            data: packageResponse.data,
            count: packageResponse.data.length,
          };
        }

        setDetails(newDetails);
      }
    } catch (error) {
      console.error("Error fetching commissions:", error);
      setCommissionHistory([]); // Set as empty array in case of error
    } finally {
      setLoading(false);
    }
  }, [effectiveRole]);

  // useEffect(() => {
  //   if (effectiveRole === "Admin" && selectedManager) {
  //     fetchCommissions(selectedManager);
  //   } else if (effectiveRole === "Client Manager") {
  //     fetchCommissions(selectedManager); // This uses their own email as manager_id
  //   }
  // }, [selectedManager, role, fetchCommissions]);
  
  useEffect(() => {
    if (effectiveRole === "Admin" && selectedManager) {
      fetchCommissions(selectedManager);
    } else if (effectiveRole === "Client Manager") {
      fetchCommissions(selectedManager);
    }
  }, [selectedManager, effectiveRole, fetchCommissions]); // ✅ Listen for effectiveRole changes
  

  

  const handleToggleDetails = async (managerId, monthYear, type) => {
    const rowKey = `${managerId}-${monthYear}-${type}`;
  
    if (expandedRows[rowKey]) {
      // Collapse if already expanded
      setExpandedRows((prev) => {
        const newRows = { ...prev };
        delete newRows[rowKey];
        return newRows;
      });
      return;
    }
  
    try {
      const endpoint = type === "appointments" ? "/api/admin/appointments" : "/api/admin/packages";
  
      const response = await axios.get(endpoint, {
        params: { manager_id: managerId, month_year: monthYear },
      });
  
      setDetails((prev) => ({
        ...prev,
        [rowKey]: {
          type,
          data: response.data,
          count: response.data.length, // Store the count of appointments or packages
        },
      }));
  
      setExpandedRows((prev) => ({
        ...prev,
        [rowKey]: true,
      }));
    } catch (error) {
      console.error(`Error fetching ${type}:`, error);
    }
  };
  

  
  const handleTogglePackages = async (managerId, monthYear) => {
    const rowKey = `${managerId}-${monthYear}-packages`;
  
    if (expandedRows[rowKey]) {
      // Collapse if already expanded
      setExpandedRows((prev) => ({ ...prev, [rowKey]: null }));
      return;
    }
  
    try {
      const response = await axios.get("/api/admin/packages", {
        params: { manager_id: managerId, month_year: monthYear },
      });
      setDetails((prev) => ({
        ...prev,
        [rowKey]: {
          type: "packages",
          data: response.data,
          count: response.data.length, // Store the count of packages
        },
      }));
      setExpandedRows((prev) => ({ ...prev, [rowKey]: { type: "packages" } }));
    } catch (error) {
      console.error("Error fetching packages:", error);
    }
  };
  
  

  const handleMarkAsPaid = async (clientManagerId, monthYear) => {
    try {
      await axios.post("/api/admin/commission/mark-paid", {
        client_manager_id: clientManagerId,
        month_year: monthYear,
      });
      // Refresh data after marking as paid
      fetchCommissions(selectedManager);
    } catch (error) {
      console.error("Error marking commission as paid:", error);
    }
  };
  

  

  const handleUpdateCommissionStatus = async (clientManagerId, monthYear, status) => {
  try {
    await axios.post("/api/admin/commission/update-status", {
      client_manager_id: clientManagerId,
      month_year: monthYear,
      status,
    });

    // Immediately update the state to reflect the new status in the UI
    setCommissionHistory((prev) => 
      prev.map((commission) =>
        commission.client_manager_email === clientManagerId && commission.month === monthYear
          ? { ...commission, status }
          : commission
      )
    );

    console.log(`Commission for ${clientManagerId} for month ${monthYear} marked as ${status}.`);
  } catch (error) {
    console.error(`Error marking commission as ${status.toLowerCase()}:`, error);
  }
};

  
return (
  <div className="mx-auto p-4">
    <h1 className="text-3xl font-bold tracking-tight text-slate-900 mb-4">
  {effectiveRole === "Admin" ? "Admin Commissions Overview" : "My Commissions"}
</h1>


    {effectiveRole === "Admin" && (
      <div className="mb-4">
        <label htmlFor="salesManager" className="block text-sm font-medium text-gray-700">
          Select Sales Manager
        </label>
        <select
  id="salesManager"
  name="salesManager"
  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
  value={selectedManager || ""}
  onChange={(e) => setSelectedManager(e.target.value)}
>
  <option value="">Select Manager</option>
  {salesManagers
    .filter((manager) => manager.email !== "jack@tutorchase.com") // Exclude "jack@tutorchase.com"
    .map((manager) => (
      <option key={manager.email} value={manager.email}>
        {manager.email}
      </option>
    ))}
</select>

      </div>
    )}

{loading ? (
  <div className="text-center">
    {effectiveRole === "Admin" ? (
      <p className="text-xl font-semibold">Select a Sales Manager...</p>
    ) : (
      <p className="text-xl font-semibold">Loading...</p>
    )}
  </div>
) : (
      <div>
        <div className="w-full overflow-hidden rounded-xl border border-gray-200 mb-6">
          <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
            <div className="text-lg font-medium leading-6 text-gray-900">
              {effectiveRole === "Admin" ? "Commission History for Selected Manager" : "Commission History"}
            </div>
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {effectiveRole === "Admin" && (
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Sales Manager
                    </th>
                  )}
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Month
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Commission
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {commissionHistory.map((commission, index) => (
                  <React.Fragment key={`${commission.client_manager_email}-${commission.month}-${index}`}>
                    <tr>
                      {effectiveRole === "Admin" && (
                        <td className="px-6 py-4 text-gray-700">
                          {commission.client_manager_email}
                        </td>
                      )}
                      <td className="px-6 py-4 text-gray-700">{commission.month}</td>
                      <td className="px-6 py-4 text-gray-700">£{parseFloat(commission.amount).toFixed(2)}</td>
                      <td className="px-6 py-4">
                        <span
                          className={`ml-4 px-2 py-1 rounded text-xs font-medium ${
                            commission.status === "Paid"
                              ? "text-green-700 bg-green-50 ring-green-600/20"
                              : "text-red-700 bg-red-50 ring-red-600/20"
                          }`}
                        >
                          {commission.status}
                        </span>
                      </td>
                      <td className="px-6 py-4 space-x-2 flex gap-2">
  <div className="flex flex-wrap items-center gap-2">
    {effectiveRole === "Admin" && (
      commission.status === "Unpaid" ? (
        <button
          className="bg-green-600 hover:bg-green-700 text-white px-3 py-1 rounded text-sm"
          onClick={() =>
            handleMarkAsPaid(commission.client_manager_email, commission.month)
          }
        >
          Mark as Paid
        </button>
      ) : (
        <button
          className="bg-yellow-500 hover:bg-yellow-600 text-white px-3 py-1 rounded text-sm"
          onClick={() =>
            handleUpdateCommissionStatus(
              commission.client_manager_email,
              commission.month,
              "Unpaid"
            )
          }
        >
          Mark as Unpaid
        </button>
      )
    )}
    <button
      className={`${
        expandedRows[`${commission.client_manager_email}-${commission.month}-appointments`]
          ? "bg-gray-600 hover:bg-gray-700"
          : "bg-gray-400 hover:bg-gray-500"
      } text-white px-3 py-1 rounded text-sm`}
      onClick={() =>
        handleToggleDetails(commission.client_manager_email, commission.month, "appointments")
      }
    >
      {expandedRows[`${commission.client_manager_email}-${commission.month}-appointments`]
        ? `Hide Lessons (${details[`${commission.client_manager_email}-${commission.month}-appointments`]?.count || 0})`
        : `Show Lessons (${details[`${commission.client_manager_email}-${commission.month}-appointments`]?.count || 0})`}
    </button>
    <button
      className={`${
        expandedRows[`${commission.client_manager_email}-${commission.month}-packages`]
          ? "bg-gray-600 hover:bg-gray-700"
          : "bg-gray-400 hover:bg-gray-500"
      } text-white px-3 py-1 rounded text-sm`}
      onClick={() =>
        handleToggleDetails(commission.client_manager_email, commission.month, "packages")
      }
    >
      {expandedRows[`${commission.client_manager_email}-${commission.month}-packages`]
        ? `Hide Packages (${details[`${commission.client_manager_email}-${commission.month}-packages`]?.count || 0})`
        : `Show Packages (${details[`${commission.client_manager_email}-${commission.month}-packages`]?.count || 0})`}
    </button>
  </div>
</td>

                    </tr>
                    {expandedRows[`${commission.client_manager_email}-${commission.month}-appointments`] && details[`${commission.client_manager_email}-${commission.month}-appointments`] && (
                      <tr>
                        <td colSpan={effectiveRole === "Admin" ? 5 : 4} className="px-6 py-4 bg-gray-50">
                          <table className="min-w-full divide-y divide-gray-200">
                            <thead>
                              <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Date
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Lesson ID
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Commission
                                </th>
                               
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Link
                                </th>
                              </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                              {details[`${commission.client_manager_email}-${commission.month}-appointments`]?.data.map((detail, index) => (
                                <tr key={index}>
                                  <td className="px-6 py-4 text-gray-700">{detail.appointment_date}</td>
                                  <td className="px-6 py-4 text-gray-700">{detail.appointment_id}</td>
                                  <td className="px-6 py-4 text-gray-700">£{parseFloat(detail.commission).toFixed(2)}</td>
                                  <td className="px-6 py-4">
                                    <a
                                      href={`https://app.tutorchase.com/cal/appointments/${detail.appointment_id}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="text-blue-500 underline"
                                    >
                                      View Appointment
                                    </a>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    )}
                    {expandedRows[`${commission.client_manager_email}-${commission.month}-packages`] && details[`${commission.client_manager_email}-${commission.month}-packages`] && (
                      <tr>
                        <td colSpan={effectiveRole === "Admin" ? 5 : 4} className="px-6 py-4 bg-gray-50">
                          <table className="min-w-full divide-y divide-gray-200">
                            <thead>
                              <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Date
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Commission
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Link
                                </th>
                              </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                              {details[`${commission.client_manager_email}-${commission.month}-packages`]?.data.map((detail, index) => (
                                <tr key={index}>
                                  <td className="px-6 py-4 text-gray-700">{detail.package_date}</td>
                                  <td className="px-6 py-4 text-gray-700">£{parseFloat(detail.commission).toFixed(2)}</td>
                                  <td className="px-6 py-4">
                                    <a
                                      href={`/clients/${detail.client_id}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="text-blue-500 underline"
                                    >
                                      View Package
                                    </a>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )}
  </div>
);
};

export default Commissions;