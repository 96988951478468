"use client";
import { useState, useEffect, Fragment, useContext } from "react";
import { RoleContext } from "../RoleContext"; // Adjust the path as needed

import { useNavigate, useLocation } from "react-router-dom"; // Import useNavigate and useLocation

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axios from "axios";
import {
  Transition,
  Dialog,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/react";
import { DataGrid } from '@mui/x-data-grid';
import { ChatBubbleLeftIcon, InformationCircleIcon, UserCircleIcon } from "@heroicons/react/20/solid";
import { ClipboardIcon } from "@heroicons/react/20/solid"; // Import an icon for the clipboard
import { GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid-pro";
import { LockClosedIcon } from "@heroicons/react/20/solid";
import {
  EllipsisHorizontalIcon,
  ClockIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/20/solid";
import ClientDialog from "./ClientDialog";
import EnquiryDialog from "./EnquiryDialog";
import { Link } from "react-router-dom";
import { formatDistanceToNow, parseISO } from "date-fns";

const initialData = {
  tasks: {},
  columns: {
    "column-1": { id: "column-1", title: "New Enquiry", taskIds: [] },
    "column-2": { id: "column-2", title: "Contacted", taskIds: [] },
    "column-3": { id: "column-3", title: "Searching For Tutor", taskIds: [] },
    "column-4": { id: "column-4", title: "Profile Sent", taskIds: [] },
  },
  columnOrder: ["column-1", "column-2", "column-3", "column-4"], // No column-5
};


// Create a custom toolbar that includes the export button
const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
};

const timeSince = (date) => {
  if (!date) {
    return "Unknown time"; // Return early if the date is undefined or null
  }
  try {
    const parsedDate = parseISO(date); // Ensure date is parsed correctly
    if (isNaN(parsedDate.getTime())) {
      console.error("Invalid date:", date);
      return "Unknown time"; // Handle invalid dates gracefully
    }
    return formatDistanceToNow(parsedDate, { addSuffix: true });
  } catch (error) {
    console.error("Error parsing date:", date, error);
    return "Unknown time"; // Handle any errors
  }
};

const statuses = {
  "New Enquiry": "text-green-700 bg-green-50 ring-green-600/20",
  Contacted: "text-yellow-700 bg-yellow-50 ring-yellow-600/20",
  "Searching For Tutor": "text-blue-700 bg-blue-50 ring-blue-600/20",
  "Profile Sent": "text-purple-700 bg-purple-50 ring-purple-600/20",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const copyToClipboard = (text) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      console.log(`${text} copied to clipboard!`);
    })
    .catch((err) => {
      console.error("Failed to copy: ", err);
    });
};

const Pipeline = () => {
  const navigate = useNavigate(); // Initialize navigate
  const location = useLocation(); // To track the current location

  const [state, setState] = useState(initialData);
  const [searchTerm, setSearchTerm] = useState(""); // Search term state
  const [isLostView, setIsLostView] = useState(false);
  const [isMarkLostDialogOpen, setIsMarkLostDialogOpen] = useState(false);
  const [selectedReason, setSelectedReason] = useState("");
  const [currentEnquiryId, setCurrentEnquiryId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentClient, setCurrentClient] = useState(null);
  const [isEnquiryDialogOpen, setIsEnquiryDialogOpen] = useState(false);
  const [clients, setClients] = useState([]);

  const [users, setUsers] = useState([]);
  const [expandedCards, setExpandedCards] = useState({});
  const [visibleTasksCount, setVisibleTasksCount] = useState(10); // Show 10 initially
  const [showColumns, setShowColumns] = useState(false); // Initialize to false to hide columns
  const [filter, setFilter] = useState("all"); // existing filters: all, new, existing
  const [isWhatsAppPopupOpen, setIsWhatsAppPopupOpen] = useState(false);
  const [whatsAppPhone, setWhatsAppPhone] = useState("");
  
  const { effectiveRole, isRoleSwitched, setEffectiveRole, setIsRoleSwitched } = useContext(RoleContext);


  const openWhatsAppPopup = (phone) => {
    const whatsappURL = `https://web.whatsapp.com/send?phone=${phone}`;
  
    // Open in a popup with twice the width (1200 instead of 600)
    window.open(
      whatsappURL,
      "_blank",
      "width=1200,height=700,left=100,top=100,scrollbars=yes,resizable=yes"
    );
  };
  

  
  const [clientManagerFilter, setClientManagerFilter] = useState("all");
  const [loggedInUser, setLoggedInUser] = useState(null); // Store the current logged-in user
  const [showLimitModal, setShowLimitModal] = useState(false); // State to control modal visibility
  const [isEnquiryInfoModalOpen, setIsEnquiryInfoModalOpen] = useState(false);
  const [enquiryInfo, setEnquiryInfo] = useState({
    frequency: "",
    details: "",
  });

  // Open the Mark Lost dialog
// const openMarkLostDialog = (enquiryId) => {
//   setCurrentEnquiryId(enquiryId);
//   setIsMarkLostDialogOpen(true);
// };

const openMarkLostDialog = (enquiryId) => {
  const task = state.tasks[enquiryId];

  
  
  if (
    effectiveRole === "Client Manager" &&
    task.details.clientManager !== loggedInUser.email
  ) {
    alert("You can only mark your own enquiries as lost.");
    return;
  }

  if (
    effectiveRole === "Client Manager" &&
    task.details.status === "New Enquiry"
  ) {
    alert("You cannot mark enquiries in the 'New Enquiry' stage as lost.");
    return;
  }

  setCurrentEnquiryId(enquiryId);
  setIsMarkLostDialogOpen(true);
};


// Close the Mark Lost dialog
const closeMarkLostDialog = () => {
  setIsMarkLostDialogOpen(false);
  setSelectedReason("");
};

// Handle marking as lost
const handleMarkAsLost = async () => {
  if (!selectedReason) {
    alert("Please select a reason for marking as lost.");
    return;
  }

  try {
    const updatedEnquiry = {
      salesbook_status: "Lost Pre-Tutor",
      status: "Lost Pre-Tutor",
      previous_status: state.tasks[currentEnquiryId]?.details.status || "Unknown",
      lost_reason: selectedReason,
    };

    await axios.put(`/api/enquiries/${currentEnquiryId}`, updatedEnquiry);
    closeMarkLostDialog();
    fetchEnquiries(); // Refresh the enquiries
  } catch (error) {
    console.error("Error marking enquiry as lost:", error.message);
    alert("Failed to mark enquiry as lost. Please try again.");
  }
};

  useEffect(() => {
    // Fetch enquiries whenever the component loads or the location (URL path) changes
    fetchEnquiries();
  }, [location.pathname]); // Track location changes to trigger data fetching

 // Create an array of *all* client manager emails
 // Right after users is defined (but inside your Pipeline component)
// Only include active Client Managers
const clientManagers = users.filter(
  (u) => u.role === "Client Manager" && u.is_active
);

const clientManagerEmails = clientManagers.map((u) => u.email);
const requiredPassers = [...clientManagerEmails, "jack@tutorchase.com"];


  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  // Inside your Pipeline component

  const hasUserPassed = (passData) => {
    if (!loggedInUser || !Array.isArray(passData)) return false;
    return passData.some((pass) => pass.passed_by === loggedInUser.email);
  };


  

  const unpassEnquiry = async (enquiryId) => {
    try {
      await axios.post(`/api/enquiries/${enquiryId}/unpass`, {
        user_email: loggedInUser.email,
      });
      console.log(`Enquiry ${enquiryId} unpassed successfully.`);

      // Optimistically update the state
      setState((prevState) => {
        const updatedTasks = { ...prevState.tasks };
        const task = updatedTasks[enquiryId];

        if (task) {
          task.details.passData = task.details.passData.filter(
            (pass) => pass.passed_by !== loggedInUser.email
          );
        }

        return { ...prevState, tasks: updatedTasks };
      });
    } catch (error) {
      console.error(`Error unpassing enquiry ${enquiryId}:`, error);
      // Optionally, notify the user of the error
    }
  };

  const [takenLeads, setTakenLeads] = useState([]);

  // const passOnEnquiry = async (enquiryId) => {
  //   try {
  //     await axios.post(`/api/enquiries/${enquiryId}/pass`, {
  //       user_email: loggedInUser.email,
  //     });
  //     console.log(`Enquiry ${enquiryId} passed successfully.`);

  //     // Optimistically update the state
  //     setState((prevState) => {
  //       const updatedTasks = { ...prevState.tasks };
  //       const task = updatedTasks[enquiryId];

  //       if (task) {
  //         task.details.passData = [
  //           ...(task.details.passData || []),
  //           {
  //             passed_by: loggedInUser.email,
  //             passed_at: new Date().toISOString(),
  //           },
  //         ];
  //       }

  //       return { ...prevState, tasks: updatedTasks };
  //     });
  //   } catch (error) {
  //     console.error(`Error passing enquiry ${enquiryId}:`, error);
  //     // Optionally, notify the user of the error
  //   }
  // };

  useEffect(() => {
    // Re-run your enquiry fetching or filtering logic when effectiveRole changes
    fetchEnquiries();
  }, [effectiveRole]);
  

  const passOnEnquiry = async (enquiryId) => {
    try {
      await axios.post(`/api/enquiries/${enquiryId}/pass`, {
        user_email: loggedInUser.email,
      });
  
      console.log(`Enquiry ${enquiryId} passed successfully.`);
  
      setState((prevState) => {
        const updatedTasks = { ...prevState.tasks };
        const task = updatedTasks[enquiryId];
  
        if (task) {
          // Add the new pass to passData
          task.details.passData = [
            ...(task.details.passData || []),
            {
              passed_by: loggedInUser.email,
              passed_at: new Date().toISOString(),
            },
          ];
  
          // Ensure Jack is in required passers
          const clientManagers = users
            .filter((u) => u.role === "Client Manager" && u.is_active)
            .map((u) => u.email);
  
          const requiredPassers = new Set([...clientManagers, "jack@tutorchase.com"]
            .map(email => email.toLowerCase().trim()));
  
          // Build a Set of who has passed
          const passedSet = new Set(task.details.passData.map(p => p.passed_by.toLowerCase().trim()));
  
          // Check if every required passer is in passedSet
          const everyoneHasPassed = [...requiredPassers].every(email => passedSet.has(email));
  
          if (everyoneHasPassed) {
            markAsLost(enquiryId);
          }
        }
  
        return { ...prevState, tasks: updatedTasks };
      });
    } catch (error) {
      console.error(`Error passing enquiry ${enquiryId}:`, error);
    }
  };
  
  

  useEffect(() => {
    const fetchLoggedInUser = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found, redirecting to login");
          return;
        }

        const response = await axios.get("/api/current-user", {
          headers: { Authorization: `Bearer ${token}` },
        });

        setLoggedInUser(response.data);
       // console.log("Logged in user:", response.data);
      } catch (error) {
        console.error("Error fetching logged-in user:", error);
      }
    };

    fetchLoggedInUser();
  }, []);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get("/api/clients");
        setClients(response.data); // Make sure the data is correctly set
        //console.log("Clients fetched in Pipeline:", response.data); // Check the fetched data
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    };

    fetchClients();
  }, []);

  useEffect(() => {
    if (!loggedInUser) {
      //console.log("No logged-in user found yet.");
      return; // Prevent running fetchEnquiries() before loggedInUser is available
    }

    //console.log("Logged in user is ready:", loggedInUser);
    fetchEnquiries();
    fetchUsers();
  }, [filter, clientManagerFilter, loggedInUser]);

  const toggleCardExpand = (taskId, forceExpand = false) => {
    setExpandedCards((prev) => ({
      ...prev,
      [taskId]: forceExpand ? true : !prev[taskId], // Toggle expanded state unless forced to expand
    }));

    const task = state.tasks[taskId];
    if (task && task.details) {
      console.log(`Client Manager: ${task.details.clientManager}`);
      console.log(`Job ID: ${task.details.job_id}`);
    } else {
      console.log("Task details not found");
    }
  };

  // Increases the visible task count by 10 when 'Load More' is clicked
  const loadMoreTasks = () => {
    setVisibleTasksCount((prevCount) => prevCount + 10);
  };

  // Filter tasks based on the search term
  const filteredTasks = (tasks) => {
    return tasks.filter((task) => {
      const taskDetails = task.details || {};
      const clientName = taskDetails.clientName || "";
      const jobId = taskDetails.job_id || "";
      const email = taskDetails.email || "";
      const phone = taskDetails.phone || "";

      // Check if any field contains the search term (case-insensitive)
      return (
        clientName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        jobId.toString().includes(searchTerm) ||
        email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        phone.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get("/api/users"); // Replace with your actual endpoint
      setUsers(response.data);
      //console.log("Users (Client Managers) fetched:", response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const getColumnKeyFromStatus = (status) => {
    switch (status) {
      case "New Enquiry":
        return "column-1";
      case "Contacted":
        return "column-2";
      case "Searching For Tutor":
        return "column-3";
      case "Profile Sent":
        return "column-4";
      default:
        return "column-1"; // Fallback
    }
  };
  
  
  

  // Fetch enquiries when the component is first loaded or navigated back to
  useEffect(() => {
    fetchEnquiries();
  }, [location.pathname]); // This triggers fetchEnquiries whenever the path changes

  // const fetchEnquiries = async () => {
  //   try {
  //     const [enquiriesResponse, clientsResponse] = await Promise.all([
  //       axios.get("/api/enquiries"),
  //       axios.get("/api/clients"),
  //     ]);

  //     let enquiries = enquiriesResponse.data;
  //     const clients = clientsResponse.data;

  //     const loggedInUserEmail = loggedInUser?.email;

  //     // **Filter out enquiries with unwanted statuses**
  //     const allowedStatuses = [
  //       "New Enquiry",
  //       "Contacted",
  //       "Searching For Tutor",
  //       "Profile Sent",
  //     ];
  //     enquiries = enquiries.filter(
  //       (enquiry) =>
  //         allowedStatuses.includes(enquiry.status) &&
  //         enquiry.salesbook_status !== "Lost Pre-Tutor"
  //     );

  //     // Process the enriched enquiries with client information
  //     const enrichedEnquiries = enquiries.map((enquiry) => {
  //       const client = clients.find(
  //         (client) => client.id === enquiry.client_id
  //       );
  //       const clientName = client
  //         ? `${client.first_name} ${client.last_name}`
  //         : "Unknown Client";
  //       const clientManager = client
  //         ? client.client_manager
  //         : "Unknown Manager";
  //       const jobId = enquiry.job_id;
  //       const tutorcruncher_id = client ? client.tutorcruncher_id : null;

  //       return {
  //         ...enquiry,
  //         clientName,
  //         clientManager,
  //         job_id: jobId,
  //         tutorcruncher_id, // Ensure tutorcruncher_id is added here
  //       };
  //     });

  //     // Apply filter logic for leads and clients
  //     let filteredEnquiries = enrichedEnquiries;
  //     if (filter === "new") {
  //       filteredEnquiries = filteredEnquiries.filter(
  //         (enquiry) => !enquiry.tutorcruncher_id
  //       );
  //     } else if (filter === "existing") {
  //       filteredEnquiries = filteredEnquiries.filter(
  //         (enquiry) => enquiry.tutorcruncher_id
  //       );
  //     }

  //     // Allow Admins and Assistants to filter by client manager
  //     if (["Admin", "Assistant"].includes(effectiveRole)) {
  //       if (clientManagerFilter !== "all") {
  //         filteredEnquiries = filteredEnquiries.filter((enquiry) => {
  //           const client = clients.find(
  //             (client) => client.id === enquiry.client_id
  //           );
  //           return client?.client_manager === clientManagerFilter;
  //         });
  //       }
  //     } else if (effectiveRole === "Client Manager") {
  //       // Include enquiries assigned to the logged-in client manager or unassigned
  //       filteredEnquiries = filteredEnquiries.filter((enquiry) => {
  //         const client = clients.find(
  //           (client) => client.id === enquiry.client_id
  //         );
  //         return (
  //           client?.client_manager === loggedInUserEmail ||
  //           !client?.client_manager
  //         );
  //       });
  //     }

  //     // Reset the column state to avoid stale data
  //     const newColumns = {
  //       "column-1": { id: "column-1", title: "New Enquiry", taskIds: [] },
  //       "column-2": { id: "column-2", title: "Contacted", taskIds: [] },
  //       "column-3": { id: "column-3", title: "Searching For Tutor", taskIds: [] },
  //       "column-4": { id: "column-4", title: "Profile Sent", taskIds: [] },
  //     };

  //     // Create new tasks and assign them to the correct column based on status
  //     const newTasks = {};
  //     filteredEnquiries.forEach((enquiry) => {
  //       const columnKey = getColumnKeyFromStatus(enquiry.status);
  //       newTasks[enquiry.id] = {
  //         id: enquiry.id.toString(),
  //         content: enquiry.clientName,
  //         details: { ...enquiry },
  //       };
  //       newColumns[columnKey].taskIds.push(enquiry.id.toString());
  //     });

  //     // Update state with the new columns and tasks
  //     setState({
  //       tasks: newTasks,
  //       columns: newColumns,
  //       columnOrder: ["column-1", "column-2", "column-3", "column-4"],
  //     });

  //     setLoading(false);
  //   } catch (error) {
  //     console.error("Error fetching enquiries:", error);
  //     setLoading(false);
  //   }
  // };

  ///NEW
  // const fetchEnquiries = async () => {
  //   try {
  //     if (!loggedInUser) {
  //       console.log("No logged-in user found yet.");
  //       return; // Prevent running if loggedInUser is not available
  //     }

  //     // Fetch enquiries from the backend with filtering
  //     const enquiriesResponse = await axios.get("/api/enquiries", {
  //       params: {
  //         role: effectiveRole,
  //         clientManagerEmail: effectiveRole === "Admin" ? null : loggedInUser.email,
  //       },
  //     });

  //     let enquiries = enquiriesResponse.data;

  //     // Log the response to ensure it contains the expected fields
  //     console.log("Fetched Enquiries:", enquiries);

  //     // Further filter based on the type of filter selected on the frontend (new vs existing)
  //     if (filter === "new") {
  //       enquiries = enquiries.filter((enquiry) => !enquiry.tutorcruncher_id);
  //     } else if (filter === "existing") {
  //       enquiries = enquiries.filter((enquiry) => enquiry.tutorcruncher_id);
  //     }

  //     // Reset the column state to avoid stale data
  //     const newColumns = {
  //       "column-1": { id: "column-1", title: "New Enquiry", taskIds: [] },
  //       "column-2": { id: "column-2", title: "Contacted", taskIds: [] },
  //       "column-3": { id: "column-3", title: "Searching For Tutor", taskIds: [] },
  //       "column-4": { id: "column-4", title: "Profile Sent", taskIds: [] },
  //     };

  //     // Create new tasks and assign them to the correct column based on status
  //     const newTasks = {};
  //     enquiries.forEach((enquiry) => {
  //       const columnKey = getColumnKeyFromStatus(enquiry.status);

  //       // Log the client manager for each enquiry
  //       console.log(`Enquiry ID: ${enquiry.id}, Client Manager: ${enquiry.client_manager}`);

  //       newTasks[enquiry.id] = {
  //         id: enquiry.id.toString(),
  //         content: `${enquiry.first_name} ${enquiry.last_name}`,
  //         details: { ...enquiry },
  //       };
  //       newColumns[columnKey].taskIds.push(enquiry.id.toString());
  //     });

  //     // Update state with the new columns and tasks
  //     setState({
  //       tasks: newTasks,
  //       columns: newColumns,
  //       columnOrder: ["column-1", "column-2", "column-3", "column-4"],
  //     });

  //     setLoading(false);
  //   } catch (error) {
  //     console.error("Error fetching enquiries:", error);
  //     setLoading(false);
  //   }
  // };

  
  const fetchLostEnquiries = async () => {
    try {
      // Fetch lost enquiries from the backend
      const response = await axios.get("/api/enquiries", {
        params: { status: "Lost Pre-Tutor" }, // Ensure backend supports this filter
      });
      const lostEnquiries = response.data;
  
      // Fetch client data for enrichment
      const clientsResponse = await axios.get("/api/clients");
      const clients = clientsResponse.data;
  
      // Enrich lost enquiries with client information
      const enrichedLostEnquiries = lostEnquiries.map((enquiry) => {
        const client = clients.find((client) => client.id === enquiry.client_id);
        return {
          ...enquiry,
          clientName: client
            ? `${client.first_name || ""} ${client.last_name || ""}`.trim() || "Unknown Client"
            : "Unknown Client",
          clientManager: client ? client.client_manager : "Unknown Manager",
          tutorcruncher_id: client ? client.tutorcruncher_id : null,
        };
      });
  
      // Update state with enriched lost enquiries
      setState((prevState) => ({
        ...prevState,
        lostEnquiries: enrichedLostEnquiries,
      }));
    } catch (error) {
      console.error("Error fetching lost enquiries:", error);
    }
  };
  

  // useEffect(() => {
  //   const storedSwitchState = localStorage.getItem("isRoleSwitched");
  //   const storedEffectiveRole = localStorage.getItem("effectiveRole");
  //   if (storedSwitchState !== null && storedEffectiveRole !== null) {
  //     setIsRoleSwitched(JSON.parse(storedSwitchState));
  //     setEffectiveRole(storedEffectiveRole);
  //   }
  // }, []);
  
  
  
  const toggleRole = () => {
    const newSwitchState = !isRoleSwitched;
    const newRole = newSwitchState ? "Client Manager" : "Admin";
    setIsRoleSwitched(newSwitchState);
    setEffectiveRole(newRole);
    localStorage.setItem("isRoleSwitched", newSwitchState);
    localStorage.setItem("effectiveRole", newRole);
  };
  
  const fetchEnquiries = async () => {
    try {
      if (!loggedInUser) {
        console.log("No logged-in user found yet.");
        return;
      }
  
      // Fetch enquiries from the backend with filtering
      const enquiriesResponse = await axios.get("/api/enquiries", {
        params: {
          role: effectiveRole,
          clientManagerEmail: ["Admin", "Assistant"].includes(effectiveRole)
            ? null
            : loggedInUser.email,
        },
      });
  
      let enquiries = enquiriesResponse.data;
  
          // Exclude "Lost Pre-Tutor" enquiries
    enquiries = enquiries.filter(
      (enquiry) => enquiry.status !== "Lost Pre-Tutor"
    );

      // Fetch client data if needed
      const clientsResponse = await axios.get("/api/clients");
      const clients = clientsResponse.data;

      
  
      // Log the response to ensure it contains the expected fields
     // console.log("Fetched Enquiries:", enquiries);
  
      // Define allowed statuses based on showLost
      let allowedStatuses = [
        "New Enquiry",
        "Contacted",
        "Searching For Tutor",
        "Profile Sent",
      ];
      
      
      
      enquiries = enquiries.filter((enquiry) => allowedStatuses.includes(enquiry.status));
      
  
      // Filter enquiries based on allowed statuses and salesbook_status
      enquiries = enquiries.filter((enquiry) => {
        if (!allowedStatuses.includes(enquiry.status)) return false;
      
        return true;
      });
  
      // Enrich enquiries with client information
      const enrichedEnquiries = enquiries.map((enquiry) => {
        const client = clients.find((client) => client.id === enquiry.client_id);
        return {
            ...enquiry,
            clientName: client
                ? `${client.first_name || ''} ${client.last_name || ''}`.trim() || 'Unknown Client'
                : 'Unknown Client', // Default to "Unknown Client" if no client is found
            clientManager: client ? client.client_manager : 'Unknown Manager',
            tutorcruncher_id: client ? client.tutorcruncher_id : null,
        };
    });
    
    
      // Apply filter logic for leads and clients
      let filteredEnquiries = enrichedEnquiries;
      if (filter === "new") {
        filteredEnquiries = filteredEnquiries.filter(
          (enquiry) => !enquiry.tutorcruncher_id
        );
      } else if (filter === "existing") {
        filteredEnquiries = filteredEnquiries.filter(
          (enquiry) => enquiry.tutorcruncher_id
        );
      } else if (filter === "unassigned") {
        filteredEnquiries = filteredEnquiries.filter((enquiry) => {
          const client = clients.find(
            (client) => client.id === enquiry.client_id
          );
          return !client?.client_manager; // Only those without a client manager
        });
      }
  
      // Allow Admins and Assistants to filter by client manager
      if (["Admin", "Assistant"].includes(effectiveRole)) {
        if (clientManagerFilter !== "all") {
          filteredEnquiries = filteredEnquiries.filter((enquiry) => {
            const client = clients.find(
              (client) => client.id === enquiry.client_id
            );
            return client?.client_manager === clientManagerFilter;
          });
        }
      } else if (effectiveRole === "Client Manager") {
        // Include enquiries assigned to the logged-in client manager or unassigned
        filteredEnquiries = filteredEnquiries.filter((enquiry) => {
          const client = clients.find(
            (client) => client.id === enquiry.client_id
          );
          return (
            client?.client_manager === loggedInUser.email ||
            !client?.client_manager
          );
        });
      }
  
      // Reset the column state to avoid stale data
      const newColumns = {
    "column-1": { id: "column-1", title: "New Enquiry", taskIds: [] },
    "column-2": { id: "column-2", title: "Contacted", taskIds: [] },
    "column-3": { id: "column-3", title: "Searching For Tutor", taskIds: [] },
    "column-4": { id: "column-4", title: "Profile Sent", taskIds: [] },
  };

      // Create new tasks and assign them to the correct column based on status
      const newTasks = {};
      filteredEnquiries.forEach((enquiry) => {
        const columnKey = getColumnKeyFromStatus(enquiry.status);
  
        newTasks[enquiry.id] = {
          id: enquiry.id.toString(),
          content: enquiry.clientName,
          details: { ...enquiry },
        };
        newTasks[enquiry.id].details.passData = enquiry.passData || []; // Ensure passData is correctly assigned
  
        newColumns[columnKey].taskIds.push(enquiry.id.toString());
      });
  
      // Update state with the new columns and tasks
      setState({
        tasks: newTasks,
        columns: newColumns,
        columnOrder: ["column-1", "column-2", "column-3", "column-4"],
      });
     // console.log("Updated state with passData:", newTasks);
  
      // If showLost is active, include column-5 in columnOrder
      
  
      setLoading(false);
    } catch (error) {
      console.error("Error fetching enquiries:", error);
      setLoading(false);
    }
  };
  

    
  useEffect(() => {
    if (isLostView) {
      // Fetch and filter lost enquiries
      fetchLostEnquiries(); // Implement fetchLostEnquiries to fetch and filter the lost entries
    } else {
      // Fetch all pipeline entries
      fetchEnquiries();
    }
  }, [isLostView]);
  
  

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     Object.values(state.tasks).forEach((task) => {
  //       if (
  //         task.details.status === "Connected" &&
  //         task.details.statuschangetime
  //       ) {
  //         const now = new Date();
  //         const statuschangetime = new Date(task.details.statuschangetime); // or parseISO(task.details.statuschangetime);
  //         console.log("Parsed statuschangetime:", statuschangetime);

  //         const timeDifference = now - statuschangetime; // Difference in milliseconds

  //         // Convert milliseconds to hours
  //         const hoursInStatus = timeDifference / (1000 * 60 * 60);

  //         if (hoursInStatus >= 72) {
  //           // Change the salesbook_status to 'lost pre-tutor'
  //           const updatedTask = {
  //             ...task,
  //             details: {
  //               ...task.details,
  //               salesbook_status: "lost pre-tutor",
  //             },
  //           };

  //           setState((prevState) => ({
  //             ...prevState,
  //             tasks: {
  //               ...prevState.tasks,
  //               [task.id]: updatedTask,
  //             },
  //           }));

  //           // Update status on the server
  //           updateEnquiryStatus(task.id, "lost pre-tutor");
  //         }
  //       }
  //     });
  //   }, 60 * 60 * 1000); // Check every hour

  //   return () => clearInterval(intervalId); // Cleanup on component unmount
  // }, [state.tasks]);

  useEffect(() => {
    const expiryPeriod = 7 * 24 * 60 * 60 * 1000; // 7 days in ms
    const warningThreshold = 6 * 24 * 60 * 60 * 1000; // 6 days in ms
  
    const checkExpiries = async () => {
      Object.values(state.tasks).forEach((task) => {
        if (!task.details.statuschangetime) {
          console.log(`Task ${task.id} has no statuschangetime, skipping.`);
          return;
        }
        const now = Date.now();
        const lastUpdated = new Date(task.details.statuschangetime).getTime();
        const age = now - lastUpdated;
        console.log(`Task ${task.id} - now: ${now}, lastUpdated: ${lastUpdated}, age: ${age}`);
  
        if (age >= expiryPeriod) {
          console.log(`Task ${task.id} is older than 7 days. Should auto-mark lost.`);
          // auto-mark logic here
        } else if (age >= warningThreshold && age < expiryPeriod) {
          if (!task.details.isExpiring) {
            console.log(`Task ${task.id} is expiring soon.`);
            const updatedTask = {
              ...task,
              details: {
                ...task.details,
                isExpiring: true,
              },
            };
            setState((prevState) => ({
              ...prevState,
              tasks: {
                ...prevState.tasks,
                [task.id]: updatedTask,
              },
            }));
          }
        } else {
          if (task.details.isExpiring) {
            console.log(`Task ${task.id} is no longer expiring.`);
            const updatedTask = {
              ...task,
              details: {
                ...task.details,
                isExpiring: false,
              },
            };
            setState((prevState) => ({
              ...prevState,
              tasks: {
                ...prevState.tasks,
                [task.id]: updatedTask,
              },
            }));
          }
        }
      });
    };
  
    // Run immediately for testing
    checkExpiries();
  
    const intervalId = setInterval(checkExpiries, 60 * 60 * 1000); // every hour
    return () => clearInterval(intervalId);
  }, [state.tasks]);
  
  
  
  
  

  const extendExpiry = async (enquiryId) => {
    try {
      // Get the current time for statuschangetime update
      const now = new Date().toISOString();
      // Calculate new expiry date: current time + 7 days
      const newExpiryDate = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString();
      
      // Get the current status from your state
      const currentStatus = state.tasks[enquiryId].details.status;
      
      // Update the enquiry on the backend with the new expiry date and updated status change time
      await axios.put(`/api/enquiries/${enquiryId}`, { 
        expiry_date: newExpiryDate,
        statuschangetime: now,
        status: currentStatus,
      });
      
      // Update your UI state accordingly
      setState((prevState) => {
        const updatedTask = { ...prevState.tasks[enquiryId] };
        updatedTask.details.expiry_date = newExpiryDate;
        updatedTask.details.statuschangetime = now;
        updatedTask.details.isExpiring = false; // Clear the expiring flag
        return {
          ...prevState,
          tasks: {
            ...prevState.tasks,
            [enquiryId]: updatedTask,
          },
        };
      });
    } catch (error) {
      console.error("Error extending expiry for enquiry", enquiryId, error);
    }
  };
  
  
  
  

  
  const onDragEnd = async (result) => {
    console.log("DragEnd triggered:", result);

    const { destination, source, draggableId } = result;

    if (!destination) {
      console.log("No destination found. Drag cancelled.");
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      console.log(
        "Dragged item dropped in the same position. No action taken."
      );
      return;
    }

    const start = state.columns[source.droppableId];
    const finish = state.columns[destination.droppableId];

    if (!start || !finish) {
      console.error("Start or Finish column not found in state:", {
        start,
        finish,
      });
      return;
    }

    let newState = { ...state };

    if (start === finish) {
      console.log("Moving within the same column");

      const newTaskIds = Array.from(start.taskIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        taskIds: newTaskIds,
      };

      newState = {
        ...state,
        columns: {
          ...state.columns,
          [newColumn.id]: newColumn,
        },
      };
    } else {
      console.log("Moving to a different column");

      const startTaskIds = Array.from(start.taskIds);
      startTaskIds.splice(source.index, 1);
      const newStart = {
        ...start,
        taskIds: startTaskIds,
      };

      const finishTaskIds = Array.from(finish.taskIds);
      finishTaskIds.splice(destination.index, 0, draggableId);
      const newFinish = {
        ...finish,
        taskIds: finishTaskIds,
      };

      newState = {
        ...state,
        columns: {
          ...state.columns,
          [newStart.id]: newStart,
          [newFinish.id]: newFinish,
        },
      };
    }

    const newStatus = Object.keys(statuses).find(
      (status) => getColumnKeyFromStatus(status) === destination.droppableId
    );

    const oldStatus = state.tasks[draggableId].details.status;
    const statuschangetime =
      oldStatus !== newStatus
        ? new Date().toISOString() // New status, update time
        : state.tasks[draggableId].details.statuschangetime; // Keep old time if status unchanged

    const updatedTask = {
      ...state.tasks[draggableId],
      details: {
        ...state.tasks[draggableId].details,
        status: newStatus,
        statuschangetime,
      },
    };

    console.log("Updated task:", updatedTask);

    setState({
      ...newState,
      tasks: {
        ...newState.tasks,
        [draggableId]: updatedTask,
      },
    });

    console.log(
      `Updating enquiry status to ${newStatus} for enquiry ${draggableId}`
    );

    // Call the backend to update the status
    await updateEnquiryStatus(draggableId, newStatus, statuschangetime);

    // After updating the status, check if we need to auto-take the lead
    // Only apply this logic if the logged-in user is a Client Manager or Admin, NOT Assistant
    // Only auto-assign if the logged-in user is a Client Manager
    if (loggedInUser && effectiveRole === "Client Manager") {
      const taskDetails = updatedTask.details;
      // If the new column is not "New Enquiry" and there's no client manager assigned
      if (newStatus !== "New Enquiry" && !taskDetails.client_manager) {
        console.log("No client manager assigned, automatically taking lead...");
        await takeLead(taskDetails.client_id);
      }
    }
  };

  const updateEnquiryStatus = async (
    enquiryId,
    newStatus,
    statuschangetime
  ) => {
    try {
      // Prepare the data to be sent to the backend
      const updatedEnquiry = {
        status: newStatus,
        statuschangetime, // Send statuschangetime to the backend
      };

      // Update the enquiry status in the backend
      await axios.put(`/api/enquiries/${enquiryId}`, updatedEnquiry);

      console.log(
        `Enquiry ${enquiryId} status successfully updated to ${newStatus}`
      );
    } catch (error) {
      console.error("Error updating enquiry status:", error);
    }
  };

  const updateClientStatus = async (clientId, newStatus) => {
    try {
      await axios.put(`/api/clients/${clientId}`, { lead_status: newStatus });
    } catch (error) {
      console.error("Error updating client status:", error);
    }
  };

  const openDialog = (client = null) => {
    setCurrentClient(client);
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setCurrentClient(null);
  };

  const saveEnquiry = async (enquiry) => {
    try {
      await axios.post(`/api/clients/${enquiry.client_id}/enquiries`, enquiry);
      fetchEnquiries(); // Refresh enquiries
    } catch (error) {
      console.error("Error saving enquiry:", error);
    }
  };

  const openEnquiryDialog = () => {
    if (clients.length > 0) {
      setIsEnquiryDialogOpen(true);
    } else {
      console.error("No clients available to select");
      // You can open the dialog anyway for testing
      setIsEnquiryDialogOpen(true);
    }
  };

  useEffect(() => {
    const checkStatusUpdate = () => {
      const statusUpdated = localStorage.getItem("enquiryStatusUpdated");
      if (statusUpdated === "true") {
        // Fetch fresh data since an enquiry's status was updated
        fetchEnquiries();
        // Reset the flag
        localStorage.setItem("enquiryStatusUpdated", "false");
      }
    };

    // Check for status update whenever the component mounts or path changes
    checkStatusUpdate();
  }, [location.pathname]); // Ensure this runs when navigating back to the pipeline

  const takeLead = async (clientId) => {
    try {
      if (!loggedInUser?.email) {
        console.error("Logged in user email not found");
        return;
      }

      // Filter out timestamps older than 1 hour
      const oneHourAgo = new Date().getTime() - 60 * 60 * 1000;
      const recentTakenLeads = takenLeads.filter(
        (timestamp) => timestamp > oneHourAgo
      );

      if (recentTakenLeads.length >= 100) {
        console.error(
          "You have reached the maximum of 100 leads within the past hour."
        );
        setShowLimitModal(true); // Show the modal instead of using an alert
        return;
      }

      // Use the logged-in user's email to assign them as the client manager
      await axios.put(`/api/clients/${clientId}`, {
        client_manager: loggedInUser.email,
      });

      // Update the state to reflect the change
      setState((prevState) => {
        const updatedTasks = { ...prevState.tasks };

        if (
          !updatedTasks[clientId.toString()] ||
          !updatedTasks[clientId.toString()].details
        ) {
          console.error(`Task not found for client ID: ${clientId}`);
          return prevState;
        }

        // Update the client manager in the task details
        updatedTasks[clientId.toString()] = {
          ...updatedTasks[clientId.toString()],
          details: {
            ...updatedTasks[clientId.toString()].details,
            clientManager: loggedInUser.email,
          },
        };

        return {
          ...prevState,
          tasks: updatedTasks,
        };
      });

      console.log(
        `Client manager for client ${clientId} updated to ${loggedInUser.email}`
      );

      // Update the list of timestamps for leads taken
      setTakenLeads([...recentTakenLeads, new Date().getTime()]);

      // Optionally, re-fetch the enquiries to ensure everything is in sync
      fetchEnquiries();
    } catch (error) {
      console.error("Error taking lead:", error);
    }
  };

  useEffect(() => {
    if (loggedInUser?.email) {
      // Load taken leads for the specific logged-in user from localStorage
      const storedLeads =
        JSON.parse(localStorage.getItem(`takenLeads_${loggedInUser.email}`)) ||
        [];
      setTakenLeads(storedLeads);
    }
  }, [loggedInUser]);

  useEffect(() => {
    if (loggedInUser?.email) {
      // Store taken leads for the specific logged-in user in localStorage whenever it changes
      localStorage.setItem(
        `takenLeads_${loggedInUser.email}`,
        JSON.stringify(takenLeads)
      );
    }
  }, [takenLeads, loggedInUser]);

  const assignClientManager = async (clientId, clientManagerEmail) => {
    try {
      await axios.put(`/api/clients/${clientId}`, {
        client_manager: clientManagerEmail || null,
      });

      // Update the state to reflect the change
      setState((prevState) => {
        // Create a deep copy of the tasks
        const updatedTasks = { ...prevState.tasks };

        // Ensure the task exists before attempting to update it
        if (
          !updatedTasks[clientId.toString()] ||
          !updatedTasks[clientId.toString()].details
        ) {
          console.error(`Task not found for client ID: ${clientId}`);
          return prevState; // Return the previous state if the task is not found
        }

        // Update the client manager in the task details
        updatedTasks[clientId.toString()] = {
          ...updatedTasks[clientId.toString()],
          details: {
            ...updatedTasks[clientId.toString()].details,
            clientManager: clientManagerEmail || null, // Use null for unassigned
          },
        };

        return {
          ...prevState,
          tasks: updatedTasks, // Use the updated tasks object
        };
      });

      console.log(
        `Client manager for client ${clientId} updated to ${
          clientManagerEmail || "Unassigned"
        }`
      );

      // Optionally, re-fetch the enquiries to make sure everything is in sync
      fetchEnquiries();
    } catch (error) {
      console.error("Error assigning client manager:", error);
    }
  };

  const closeEnquiryDialog = () => {
    setIsEnquiryDialogOpen(false);
  };

  

  // const saveClient = async (client) => {
  //   if (client.id) {
  //     // Edit existing client
  //     try {
  //       await axios.put(`/api/clients/${client.id}`, client);

  //       const updatedTask = {
  //         id: client.id.toString(),
  //         content: `${client.first_name} ${client.last_name}`,
  //         details: client,
  //       };
  //       const columnKey = getColumnKeyFromStatus(client.lead_status);
  //       setState((prevState) => {
  //         const newTasks = {
  //           ...prevState.tasks,
  //           [client.id.toString()]: updatedTask,
  //         };
  //         const newColumns = { ...prevState.columns };
  //         Object.keys(newColumns).forEach((key) => {
  //           newColumns[key].taskIds = newColumns[key].taskIds.filter(
  //             (id) => id !== client.id.toString()
  //           );
  //         });
  //         if (!newColumns[columnKey].taskIds.includes(client.id.toString())) {
  //           // Check for duplicate
  //           newColumns[columnKey].taskIds.push(client.id.toString());
  //         }
  //         return { ...prevState, tasks: newTasks, columns: newColumns };
  //       });
  //     } catch (error) {
  //       console.error("Error editing client:", error);
  //     }
  //   } else {
  //     // Add new client
  //     try {
  //       const response = await axios.post("/api/clients", client);
  //       const newClient = response.data;
  //       const newTask = {
  //         id: newClient.id.toString(),
  //         content: `${newClient.first_name} ${newClient.last_name}`,
  //         details: newClient,
  //       };
  //       const columnKey = getColumnKeyFromStatus(newClient.lead_status);
  //       setState((prevState) => ({
  //         ...prevState,
  //         tasks: { ...prevState.tasks, [newClient.id.toString()]: newTask },
  //         columns: {
  //           ...prevState.columns,
  //           [columnKey]: {
  //             ...prevState.columns[columnKey],
  //             taskIds: [
  //               ...prevState.columns[columnKey].taskIds.filter(
  //                 (id) => id !== newClient.id.toString()
  //               ),
  //               newClient.id.toString(),
  //             ], // Check for duplicate
  //           },
  //         },
  //       }));
  //     } catch (error) {
  //       console.error("Error adding client:", error);
  //     }
  //   }
  //   await fetchEnquiries();

  //   closeDialog();
  // };

  const saveClient = async (client) => {
   // console.log("saveClient called with:", client);

    try {
        let newClient = null;

        if (client.id) {
            // ====== EDIT EXISTING CLIENT ======
            await axios.put(`/api/clients/${client.id}`, client);

            // Update the client in local state
            const updatedTask = {
                id: client.id.toString(),
                content: `${client.first_name} ${client.last_name}`,
                details: client,
            };
            const columnKey = getColumnKeyFromStatus(client.lead_status);

            setState((prevState) => {
                const newTasks = {
                    ...prevState.tasks,
                    [client.id.toString()]: updatedTask,
                };
                const newColumns = { ...prevState.columns };

                // Remove client from any old column
                Object.keys(newColumns).forEach((key) => {
                    newColumns[key].taskIds = newColumns[key].taskIds.filter(
                        (id) => id !== client.id.toString()
                    );
                });

                // Add to the correct column
                if (!newColumns[columnKey].taskIds.includes(client.id.toString())) {
                    newColumns[columnKey].taskIds.push(client.id.toString());
                }

                return { ...prevState, tasks: newTasks, columns: newColumns };
            });

            newClient = client; // Store reference for enquiries (if any)
        } else {
            // ====== CREATE NEW CLIENT ======
            const response = await axios.post("/api/clients", client);
            newClient = response.data;

            // Update the newly created client in local state
            const newTask = {
                id: newClient.id.toString(),
                content: `${newClient.first_name} ${newClient.last_name}`,
                details: newClient,
            };
            const columnKey = getColumnKeyFromStatus(newClient.lead_status);

            setState((prevState) => ({
                ...prevState,
                tasks: { ...prevState.tasks, [newClient.id.toString()]: newTask },
                columns: {
                    ...prevState.columns,
                    [columnKey]: {
                        ...prevState.columns[columnKey],
                        taskIds: [
                            ...prevState.columns[columnKey].taskIds.filter(
                                (id) => id !== newClient.id.toString()
                            ),
                            newClient.id.toString(),
                        ],
                    },
                },
            }));
        }

        // ====== RE-FETCH ENQUIRIES ======
        await fetchEnquiries();

        // Close the dialog
        closeDialog();
    } catch (error) {
        console.error("Error creating/editing client:", error);
    }
};

  

  const markAsLost = async (enquiryId) => {
    try {
      // Get the current status to update `previous_status`
      const currentStatus = state.tasks[enquiryId]?.details.status || "Unknown";
  
      // Prepare the updated enquiry data
      const updatedEnquiry = {
        salesbook_status: "Lost Pre-Tutor",
        status: "Lost Pre-Tutor",
        previous_status: currentStatus, // Save the current status as `previous_status`
      };
  
      // Update the enquiry status on the server
      await axios.put(`/api/enquiries/${enquiryId}`, updatedEnquiry);
  
      // Update the state to remove the enquiry from the current pipeline
      setState((prevState) => {
        const updatedTasks = { ...prevState.tasks };
        const updatedColumns = { ...prevState.columns };
  
        // Remove the enquiry from all columns
        Object.keys(updatedColumns).forEach((colId) => {
          updatedColumns[colId].taskIds = updatedColumns[colId].taskIds.filter(
            (id) => id !== enquiryId.toString()
          );
        });
  
        // Remove the task from the task list
        delete updatedTasks[enquiryId];
  
        return {
          tasks: updatedTasks,
          columns: updatedColumns,
          columnOrder: prevState.columnOrder,
        };
      });
  
      console.log(`Enquiry ${enquiryId} marked as 'Lost Pre-Tutor' successfully.`);
    } catch (error) {
      console.error("Error marking enquiry as 'Lost Pre-Tutor':", error.message);
      alert("Failed to mark enquiry as lost. Please try again.");
    }
  };
  
  

  
  
  const openEnquiryInfoModal = async (enquiryId) => {
    try {
      // Fetch enquiry details
      const enquiryResponse = await axios.get(
        `/api/enquiry-details/${enquiryId}`
      );
      console.log("Enquiry Response:", enquiryResponse.data); // Log to check client_id

      console.log("Enquiry Response:", enquiryResponse.data); // Log the entire response
      const clientId = enquiryResponse.data.client_id;
      if (!clientId) {
        console.error("Client ID is missing in enquiry response");
        return;
      }

      // Fetch client details
      const clientResponse = await axios.get(`/api/clients/${clientId}`);

      // Set state for enquiry and client information
      setEnquiryInfo({
        frequency: enquiryResponse.data.frequency_duration || "Not provided",
        budget: enquiryResponse.data.hourly_budget || "Not provided",
        details: enquiryResponse.data.details || "No details available",
      });

      setCurrentClient({
        name: `${clientResponse.data.first_name} ${clientResponse.data.last_name}`,
        email: clientResponse.data.email,
        phone: clientResponse.data.phone,
        form_host: clientResponse.data.form_host,
        country: clientResponse.data.country,
        customer_type: clientResponse.data.customer_type,
        clientManager: clientResponse.data.client_manager, // include assigned manager if available
      });
      

      // Open the modal
      setIsEnquiryInfoModalOpen(true);
    } catch (error) {
      console.error("Error fetching enquiry or client info:", error);
    }
  };

  const deleteClient = async (client) => {
    try {
      await axios.delete(`/api/clients/${client.id}`);
      const newTasks = { ...state.tasks };
      delete newTasks[client.id];

      const newColumns = { ...state.columns };
      Object.keys(newColumns).forEach((columnId) => {
        newColumns[columnId].taskIds = newColumns[columnId].taskIds.filter(
          (id) => id !== client.id.toString()
        );
      });

      setState({
        ...state,
        tasks: newTasks,
        columns: newColumns,
      });
    } catch (error) {
      console.error(
        "Error deleting client:",
        error.response?.data || error.message
      );
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
        <h1 className="mt-3 text-3xl font-bold tracking-tight text-slate-900">
          Pipeline
        </h1>

        <div className="mt-3 flex sm:ml-4 sm:mt-0">
          <input
            type="text"
            placeholder="Search Pipeline"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="mr-4 border border-gray-300 rounded-md px-3 py-2 text-sm w-64" // Adjust width for better appearance
          />
          <button
            onClick={() => openDialog()}
            type="button"
            className="mr-4 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Add New Client
          </button>
          <button
            onClick={openEnquiryDialog}
            type="button"
            className="mr-4 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Add New Enquiry
          </button>

           

          <button
            onClick={() => setFilter("all")}
            className={`mr-4 inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm ${
              filter === "all"
                ? "bg-blue-500 text-white"
                : "bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            }`}
          >
            Show All Enquiries
          </button>
          
          
        
          <button
            onClick={() => setFilter("unassigned")}
            className={`mr-4 inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm ${
              filter === "unassigned"
                ? "bg-orange-500 text-white"
                : "bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            }`}
          >
            Show Unassigned Enquiries
          </button>

          {effectiveRole === "Admin" && (
  <button
    onClick={() => setIsLostView((prev) => !prev)}
    className={`mr-4 inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm ${
      isLostView ? "bg-red-500 text-white" : "bg-green-500 text-white"
    }`}
  >
    {isLostView ? "Show Pipeline" : "Show Lost"}
  </button>
)}


          {/* <button
            onClick={() => setFilter("new")} // Set filter to show leads
            className={`mr-4 inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm ${
              filter === "new"
                ? "bg-blue-500 text-white"
                : "bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            }`}
          >
            Show From Leads
          </button>

          <button
            onClick={() => setFilter("existing")} // Set filter to show clients
            className={`mr-4 inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm ${
              filter === "existing"
                ? "bg-blue-500 text-white"
                : "bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            }`}
          >
            Show From Clients
          </button> */}

          {["Admin", "Assistant"].includes(effectiveRole) && (
            <select
              value={clientManagerFilter}
              onChange={(e) => setClientManagerFilter(e.target.value)}
              className="mr-4 inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              <option value="all">All Managers</option>
              {users.map((user) => (
                <option key={user.id} value={user.email}>
                  {user.name || user.email}
                </option>
              ))}
            </select>
          )}
        </div>
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        <div className="flex gap-4 p-6">
        {isLostView ? (
  <DataGrid
  rows={(state.lostEnquiries || []).filter(row => row.status === "Lost Pre-Tutor")} // Inline filter
  columns={[

      {
        field: "clientName",
        headerName: "Client Name",
        width: 150,
        renderCell: (params) => (
          <Link
            to={`/clients/${params.row.client_id}`}
            className="text-blue-500 underline"
          >
            {params.value || "Unknown Client"}
          </Link>
        ),
      },
            { field: "email", headerName: "Email", width: 200 },
            { field: "phone", headerName: "Phone", width: 150 },
            { field: "client_manager", headerName: "Client Manager", width: 200 },
            { field: "job_id", headerName: "Job ID", width: 100 },
            // { field: "lead_source", headerName: "Lead Source", width: 150 },
            {
              field: "created_at",
              headerName: "Created At",
              width: 150,
              
            },
            // {
            //   field: "statuschangetime",
            //   headerName: "Last Status Change",
            //   width: 200,
             
            // },
            { field: "status", headerName: "Status", width: 150 },
            { field: "previous_status", headerName: "Previous Status", width: 150 },
            { field: "lost_reason", headerName: "Lost Reason", width: 150 },
          //  { field: "lost_pre_tutor", headerName: "Lost Pre-Tutor", width: 150 },
            {
              field: "passData",
              headerName: "Pass Data",
              width: 250,
              renderCell: (params) =>
                Array.isArray(params.value)
                  ? params.value
                      .map((p) => `${p.passed_by.split("@")[0]} (Passed: ${new Date(p.passed_at).toLocaleDateString()})`)
                      .join(", ")
                  : "N/A",
            },
            // { field: "hourly_budget", headerName: "Hourly Budget", width: 150 },
            // { field: "frequency_duration", headerName: "Frequency", width: 150 },
          ]}
          pageSize={10}
          rowsPerPageOptions={[10, 25, 50]}
          pagination
          autoHeight
          slots={{
            toolbar: CustomToolbar, // Add your custom toolbar to include export functionality
          }}
        />
     

 
) : (


        state.columnOrder.map((columnId) => {
          const column = state.columns[columnId] || { taskIds: [] };
          const tasks = (column.taskIds || []).map(
            (taskId) => state.tasks[taskId] || {}
          );
          

    return (
                <div
                  key={column.id}
                  className="flex-1 bg-gray-100 p-4 rounded-lg shadow"
                >
                  <h2 className="text-lg font-bold mb-4">{column.title}</h2>
                  <Droppable droppableId={column.id} key={column.id}>
                    {(provided) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className="overflow-hidden rounded-xl mb-2"
                      >
                        {tasks
                          .filter((task) => {
                            if (!task || !task.details) return false; // Safeguard: Ensure task and details exist

                            
                            if (
                              effectiveRole === "Client Manager" &&
                              hasUserPassed(task.details.passData)
                            ) {
                              return false;
                            }

                            // Apply filter logic for search term
                            const taskDetails = task.details || {};
                            const clientName = taskDetails.clientName || "";
                            const jobId = taskDetails.job_id || "";
                            const email = taskDetails.email || "";
                            const phone = taskDetails.phone || "";

                            // Check if any field contains the search term (case-insensitive)
                            const matchesSearchTerm =
                              clientName
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase()) ||
                              jobId.toString().includes(searchTerm) ||
                              email
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase()) ||
                              phone
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase());

                            // Apply existing new/existing filter logic
                            if (filter === "new") {
                              return (
                                matchesSearchTerm &&
                                !task.details.tutorcruncher_id
                              );
                            }

                            if (filter === "existing") {
                              return (
                                matchesSearchTerm &&
                                task.details.tutorcruncher_id
                              );
                            }

                            return matchesSearchTerm; // Default case if no specific filter
                          })
                          .slice(0, visibleTasksCount)
                          .map((task, index) => {
                            if (!task || !task.details) return null; // Safeguard: Ensure task and details exist

                            const isExpanded = expandedCards[task.id]; // Safeguard task.id

                            return (
                              <Draggable key={task.id} draggableId={task.id} index={index}>
  {(provided) => (
    <div
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      className="relative overflow-hidden rounded-xl border border-gray-200 mb-2"
    >
      {task.details.isExpiring && (
        <div className="absolute top-2 right-2 flex items-center space-x-1 bg-white bg-opacity-80 rounded p-1">
          <ClockIcon className="h-4 w-4 text-red-600" /> <p className="ml-2 inline-flex items-center rounded-full px-2 py-1 text-xs font-medium text-red-800 ">Expiring Soon</p>
          <button
            onClick={(e) => {
              e.stopPropagation();
              extendExpiry(task.id);
            }}
            className="ml-2 inline-flex items-center rounded-full px-2 py-1 text-xs font-medium text-green-800 hover:bg-green-200 bg-green-100"
          >
            Extend
          </button>
        </div>
      )}
      <div
        className="flex items-center gap-x-4 border-b border-gray-900/5 bg-white p-6 cursor-pointer"
        onClick={() => toggleCardExpand(task.id)}
      >
        <div className="text-sm font-medium leading-6 text-gray-900 flex flex-col">
          <div className="flex items-center">
            <span
              className={`inline-block w-2.5 h-2.5 rounded-full mr-2 ${
                task.details.tutorcruncher_id ? "bg-green-500" : "bg-orange-500"
              }`}
            ></span>
            {task.content}
               {/* Show Take Lead button for Client Managers and Admins if no clientManager assigned */}
{["Client Manager", "Admin"].includes(effectiveRole) &&
  !task.details.clientManager &&
  column.id === "column-1" && ( // Only show in the New Enquiry column
    <button
      onClick={(e) => {
        e.stopPropagation();
        takeLead(task.details.client_id);
      }}
      className="ml-2 inline-flex items-center rounded-full bg-blue-100 px-2 py-1 text-xs font-medium text-blue-800 hover:bg-blue-200"
    >
      Take Lead
    </button>
)}

                                        </div>
          <div className="text-xs text-gray-500">
            Created {timeSince(task.details.created_at)}
          </div>
          <div className="text-xs text-gray-500">
            Status last changed {timeSince(task.details.statuschangetime)}

                                       {(effectiveRole === "Admin" || 
  (effectiveRole === "Client Manager" && 
   task.details.clientManager === loggedInUser.email && 
   task.details.status !== "New Enquiry")) && (
  <button
    onClick={(e) => {
      e.stopPropagation();
      openMarkLostDialog(task.details.id);
    }}
    className="ml-2 inline-flex items-center rounded-full bg-red-100 px-2 py-1 text-xs font-medium text-red-800 hover:bg-red-200"
  >
    Mark Lost
  </button>
)}


                                           {/* Show Pass button if user hasn't already passed */}
  {!hasUserPassed(task.details.passData) && !task.details.clientManager && (
    <button
      onClick={(e) => {
        e.stopPropagation();
        passOnEnquiry(task.details.id);
      }}
      className="ml-2 inline-flex items-center rounded-full bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-800 hover:bg-yellow-200"
    >
      Pass
    </button>
  )}


                                          <div className="gap-x-4">
  {/* Conditionally render the div only if there is no client manager assigned */}
  {!task.details.clientManager && (
  <dd className="text-gray-700">
    {effectiveRole === "Admin"
      ? Array.isArray(task.details.passData) && task.details.passData.length > 0
        ? `${task.details.passData
            .map(pass => capitalizeFirstLetter(pass.passed_by.split("@")[0]))
            .join(" / ")} Passed`
        : "No Passes"
      : (() => {
          const passedUsers = new Set((task.details.passData || []).map(p => p.passed_by.toLowerCase().trim()));
          
          // Define required passers including Jack
          const clientManagers = users
            .filter((u) => u.role === "Client Manager" && u.is_active)
            .map((cm) => cm.email.toLowerCase().trim());

          const requiredPassers = new Set([...clientManagers, "jack@tutorchase.com"]);

          return `${passedUsers.size}/${requiredPassers.size} Passed`;
        })()}
  </dd>
)}


</div>


                                        </div>
                                      </div>

                                      {/* <Menu
                                        as="div"
                                        className="relative ml-auto"
                                      >
                                        <MenuButton
                                          className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500"
                                          onClick={(e) => {
                                            e.stopPropagation(); // Prevent card collapse
                                            toggleCardExpand(task.id, true); // Force expand the card
                                          }}
                                        >
                                          <span className="sr-only">
                                            Open options
                                          </span>
                                          <EllipsisHorizontalIcon
                                            aria-hidden="true"
                                            className="h-5 w-5"
                                          />
                                        </MenuButton>
                                        <MenuItems
                                          className="absolute right-0 z-50 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none"
                                          onClick={(e) => e.stopPropagation()} // Prevent menu click from collapsing the card
                                        >
                                          <MenuItem>
                                            <Link
                                              to={`/clients/${task.details.client_id}`}
                                              className="block px-3 py-1 text-sm leading-6 text-gray-900"
                                              onClick={(e) =>
                                                e.stopPropagation()
                                              } // Prevent propagation
                                            >
                                              Client Details
                                              <span className="sr-only">
                                                , {task.content}
                                              </span>
                                            </Link>
                                          </MenuItem>
                                          <MenuItem
                                            as="button"
                                            onClick={async (e) => {
                                              e.stopPropagation();
                                              await openEnquiryInfoModal(
                                                task.details.id
                                              ); // Pass the enquiry ID to fetch the info
                                            }}
                                            className="block px-3 py-1 text-sm leading-6 text-gray-900"
                                          >
                                            Enquiry Info
                                          </MenuItem>

                                          {effectiveRole ===
                                            "Client Manager" &&
                                            !task.details.clientManager && (
                                              <MenuItem
                                                as="button"
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  takeLead(task.content);
                                                }}
                                                className="block px-3 py-1 text-sm leading-6 text-gray-900"
                                              >
                                                Take Lead
                                              </MenuItem>
                                            )}

                                        
                                        </MenuItems>
                                      </Menu> */}
                                    </div>

                                    {/* Conditionally show card body */}
                                    {isExpanded && (
                                      <dl className="divide-y divide-gray-100 px-6 py-4 text-sm leading-6 bg-white">
                                        {/* <div className="gap-x-4 py-3">
                                          <dt className="text-gray-500">
                                            Student
                                          </dt>
                                          <dd className="text-gray-700">
                                            {task.details.student_first_name}{" "}
                                            {task.details.student_last_name}
                                          </dd>
                                        </div> */}
                                        { (effectiveRole === "Admin" || task.details.clientManager) ? (
      <>
        <div className="gap-x-4 py-3">
          <dt className="text-gray-500">Email</dt>
          <dd className="text-gray-700">{task.details.email}</dd>
        </div>
        <div className="gap-x-4 py-3">
          <dt className="text-gray-500">Phone</dt>
          <dd className="text-gray-700">{task.details.phone}</dd>
        </div>
      </>
    ) : (
      <div className="flex items-center justify-center p-4 bg-gray-50 border border-dashed border-gray-300 rounded-md">
    <LockClosedIcon className="h-6 w-6 text-gray-400 mr-2" />
    <p className="text-sm text-gray-600 font-medium">
      Contact details are hidden until you take this lead.
    </p>
  </div>
    )}
                                        <div className="gap-x-4 py-3">
                                          <dt className="text-gray-500">
                                            Client Manager
                                          </dt>
                                          {/* Show only the client manager's name if the user is a 'Client Manager' */}
                                          {effectiveRole ===
                                            "Client Manager" && (
                                            <dd className="text-gray-700">
                                              {task.details.client_manager ||
                                                "Unassigned"}
                                            </dd>
                                          )}

                                          {["Admin"].includes(
                                            effectiveRole
                                          ) && (
                                            <dd className="text-gray-700">
                                              <select
                                                value={
                                                  task.details.client_manager ||
                                                  ""
                                                } // Set to empty string if no client manager
                                                onChange={(e) => {
                                                  const selectedValue =
                                                    e.target.value || null; // Use `null` for the empty option
                                                  assignClientManager(
                                                    task.details.client_id,
                                                    selectedValue
                                                  );
                                                }}
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                                              >
                                                <option value="">
                                                  Select a client manager
                                                </option>{" "}
                                                {/* This option represents 'unassigned' */}
                                                {users.map((user) => (
                                                  <option
                                                    key={user.id}
                                                    value={user.email}
                                                  >
                                                    {user.name || user.email}
                                                  </option>
                                                ))}
                                              </select>
                                            </dd>
                                          )}
                                        </div>

                                        <div className="gap-x-4 py-3">
                                          <dt className="text-gray-500">
                                            Job ID
                                          </dt>
                                          <dd className="text-gray-700">
                                            {task.details.job_id}
                                          </dd>
                                        </div>
                                        {/* <div className="gap-x-4 py-3">
                                          <dt className="text-gray-500">
                                            Phone
                                          </dt>
                                          <dd className="text-gray-700">
                                            {task.details.phone}
                                          </dd>
                                        </div> */}
                                        <div className="gap-x-4 py-3">
                                          <dt className="text-gray-500">
                                            Source
                                          </dt>
                                          <dd className="text-gray-700">
                                            {task.details.lead_source}
                                          </dd>
                                        </div>
                                        <div className="gap-x-4 py-3">
                                          <dt className="text-gray-500">
                                            Status
                                          </dt>
                                          <dd className="flex items-start gap-x-2">
                                            <div
                                              className={classNames(
                                                statuses[task.details.status],
                                                "rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset"
                                              )}
                                            >
                                              {task.details.status}
                                            </div>
                                          </dd>
                                        </div>
                                      </dl>
                                    )}
<div className="p-2 border-t border-gray-200 bg-white flex justify-evenly items-center">
  {/* Only show WhatsApp button when restricted */}
  {(effectiveRole === "Admin" || task.details.clientManager) && task.details.phone && (
    <button
      onClick={(e) => {
        e.stopPropagation();
        openWhatsAppPopup(task.details.phone);
      }}
      className="flex flex-col items-center text-green-600 hover:text-green-800 text-xs font-bold"
    >
      <ChatBubbleLeftIcon className="h-5 w-5 mb-1" />
    </button>
  )}

  {/* Enquiry Info button is always visible */}
  <button
    onClick={(e) => {
      e.stopPropagation();
      openEnquiryInfoModal(task.details.id);
    }}
    className="flex flex-col items-center text-blue-600 hover:text-blue-800 text-xs font-bold"
  >
    <InformationCircleIcon className="h-5 w-5 mb-1" />
  </button>

  {/* Only show Client Details when restricted */}
  {(effectiveRole === "Admin" || task.details.clientManager) && (
    <Link
      to={`/clients/${task.details.client_id}`}
      onClick={(e) => e.stopPropagation()}
      className="flex flex-col items-center text-purple-600 hover:text-purple-800 text-xs font-bold"
    >
      <UserCircleIcon className="h-5 w-5 mb-1" />
    </Link>
  )}
</div>


                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                        {tasks.length > visibleTasksCount && (
                          <button
                            onClick={loadMoreTasks}
                            className="mt-4 w-full rounded-md bg-blue-500 text-white py-2"
                          >
                            Load More
                          </button>
                        )}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
      </div>
    );
  })
)}
        </div>
      </DragDropContext>

      <ClientDialog
        isOpen={isDialogOpen}
        closeModal={closeDialog}
        client={currentClient}
        saveClient={saveClient}
        fetchEnquiries={fetchEnquiries}  // so the dialog can directly call it
      />
      <EnquiryDialog
        isOpen={isEnquiryDialogOpen}
        closeModal={closeEnquiryDialog}
        saveEnquiry={saveEnquiry}
        clients={clients} // Ensure the clients array is passed correctly
      />

<Dialog
  open={isMarkLostDialogOpen}
  onClose={closeMarkLostDialog}
  className="relative z-10"
>
  <Transition.Child>
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
  </Transition.Child>

  <div className="fixed inset-0 z-10 overflow-y-auto">
    <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
      <Transition.Child>
        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
          <Dialog.Title className="text-lg font-medium leading-6 text-gray-900">
            Mark Enquiry as Lost
          </Dialog.Title>
          <div className="mt-2">
            <label htmlFor="reason" className="block text-sm font-medium text-gray-700">
              Select a Reason
            </label>
            <select
              id="reason"
              value={selectedReason}
              onChange={(e) => setSelectedReason(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
            >
              <option value="">-- Select a Reason --</option>
              <option value="No Reply">No Reply</option>
              <option value="Too Expensive">Low Value</option>
              <option value="Found Other Provider">Found Other Provider</option>
              <option value="Duplicate">Duplicate</option>
              <option value="Not Suitable">Not Suitable</option>
              <option value="Low Hours">Low Hours</option>

            </select>
          </div>
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className="inline-flex w-full justify-center rounded-md bg-red-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
              onClick={handleMarkAsLost}
            >
              Mark Lost
            </button>
            <button
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-4 py-2 text-sm font-semibold text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto"
              onClick={closeMarkLostDialog}
            >
              Cancel
            </button>
          </div>
        </Dialog.Panel>
      </Transition.Child>
    </div>
  </div>
</Dialog>

      <Dialog
        open={showLimitModal}
        onClose={() => setShowLimitModal(false)}
        className="relative z-10"
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon
                      aria-hidden="true"
                      className="h-6 w-6 text-red-600"
                    />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Limit Reached
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        You have reached the maximum of 100 leads within the past
                        hour. Please wait before taking more leads.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    onClick={() => setShowLimitModal(false)}
                    className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
                  >
                    OK
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={isEnquiryInfoModalOpen}
        onClose={() => setIsEnquiryInfoModalOpen(false)}
        className="relative z-10"
      >
        <Transition.Child>
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child>
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <EllipsisHorizontalIcon className="h-6 w-6 text-blue-600" />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title className="text-lg font-medium leading-6 text-gray-900">
                      Enquiry Information
                    </Dialog.Title>
                    <div className="mt-2">
                    {currentClient ? (
  <>
    <p className="text-sm text-gray-500 mb-2 flex items-center">
      <strong>Name:&nbsp;</strong> {currentClient.name}
      <div
        className="ml-2 flex items-center bg-gray-100 text-gray-600 rounded-full px-2 py-1 text-xs cursor-pointer hover:bg-gray-200"
        onClick={() => copyToClipboard(currentClient.name)}
      >
        <ClipboardIcon className="h-4 w-4 mr-1" />
        Copy
      </div>
    </p>
    {(effectiveRole === "Admin" ||
  (effectiveRole === "Client Manager" && currentClient.clientManager === loggedInUser.email)) ? (
  <>
   
   <p className="text-sm text-gray-500 mb-2 flex items-center"><strong>Email:&nbsp;</strong> {currentClient.email}
   
   <div
        className="ml-2 flex items-center bg-gray-100 text-gray-600 rounded-full px-2 py-1 text-xs cursor-pointer hover:bg-gray-200"
        onClick={() => copyToClipboard(currentClient.email)}
      >
        <ClipboardIcon className="h-4 w-4 mr-1" />
        Copy
      </div>
      
      </p>
 
      <p className="text-sm text-gray-500 mb-2 flex items-center"><strong>Phone:&nbsp;</strong> {currentClient.phone}
      
      <div
        className="ml-2 flex items-center bg-gray-100 text-gray-600 rounded-full px-2 py-1 text-xs cursor-pointer hover:bg-gray-200"
        onClick={() => copyToClipboard(currentClient.phone)}
      >
        <ClipboardIcon className="h-4 w-4 mr-1" />
        Copy
      </div>
      
      </p>
  
  </>
) : (
  <div className="flex items-center justify-center p-4 bg-gray-50 border border-dashed border-gray-300 rounded-md">
    <LockClosedIcon className="h-6 w-6 text-gray-400 mr-2" />
    <p className="text-sm text-gray-600 font-medium">
      Contact details are hidden until you take this lead.
    </p>
  </div>
)}

    <p className="text-sm text-gray-500 mb-2">
      <strong>Form Host:</strong> {currentClient.form_host}
    </p>
    <p className="text-sm text-gray-500 mb-2">
      <strong>Country:</strong> {currentClient.country}
    </p>
    <p className="text-sm text-gray-500 mb-2">
      <strong>Customer Type:</strong> {currentClient.customer_type}
    </p>
  </>
) : (
  <p className="text-sm text-gray-500 mb-2">
    Loading client information...
  </p>
)}

                      <p className="text-sm text-gray-500 mb-2">
                        <strong>Frequency:</strong> {enquiryInfo.frequency}
                      </p>
                      <p className="text-sm text-gray-500 mb-2">
                        <strong>Hourly Budget:</strong> {enquiryInfo.budget}
                      </p>
                      <p className="text-sm text-gray-500 mt-1">
                        <strong>Details:</strong> {enquiryInfo.details}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
  <button
    type="button"
    className="inline-flex w-full justify-center rounded-md bg-blue-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
    onClick={() => setIsEnquiryInfoModalOpen(false)}
  >
    Close
  </button>
  {(effectiveRole === "Admin" ||
  (effectiveRole === "Client Manager" && currentClient?.clientManager === loggedInUser.email)) && (
  <ClipboardIcon
    onClick={() =>
      copyToClipboard(
        `${currentClient.name} - ${currentClient.email} - ${currentClient.phone}`
      )
    }
    className="inline-block ml-2 h-5 w-5 text-gray-300 cursor-pointer"
  />
)}

</div>

              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default Pipeline;
